import React from 'react';
import {
	Grid, Paper, FormControl, FormControlLabel, Button,
	FormLabel, RadioGroup, Radio, Select, MenuItem, Table, TableBody, TableRow, TableCell, TextField
} from '@material-ui/core';
import { Typography } from '@material-ui/core';
import { Formik } from 'formik';
import { inject, observer } from "mobx-react";

let id = 0;
function nextId() {
	return ++id;
}

// function sortAsc(field) {
// 	return function (a, b) {
// 		console.log({ a, b });
// 		return a.props[field] > b.props[field];
// 	}
// }

class ProductInvestorNew extends React.Component {
	render() {
		const { classes, initialValues } = this.props;
		const { persons, fis, accounts, currencyIdx } = this.props.data;

		const aFis = fis.data;
		const aPersons = persons.data;
		const aAccounts = accounts.data;

		return (
			<Grid
				container key={nextId()}
				direction="row"
				justify="space-evenly"
				alignItems="center"
				style={{ borderTop: '5px solid rgba(100, 205, 100,0.5)' }}
			>
				<Grid key={nextId()} item xs={12}>
					<Paper className={classes.paper}
						style={{ boxShadow: '0px 20px 40px rgba(0,0,0,0.06)' }}>
						<Grid container spacing={0}>

							<Grid item xs={12}>

								<Formik
									initialValues={initialValues}
									onSubmit={(values, actions) => {
										this.props.onSubmit(values);
									}}
									render={(props) => {

										let fromEntityMenuItems = [];
										if (props.values.fromPFI === 'person') {
											fromEntityMenuItems = [...aPersons.map(a => <MenuItem key={`person-${a.id}`} value={a}>{a.first_name} {a.last_name} ({a.id}:{a.unique_account_id})</MenuItem>)];
										} else {
											fromEntityMenuItems = [...aFis.map(a => <MenuItem key={`fi-${a.id}`} value={a}>{a.name} ({a.id})</MenuItem>)];
										}

										let fromAccountMenuItems = [];
										if (props.values.fromPFI) {
											fromAccountMenuItems = [...aAccounts.filter(a => {
												if (props.values.fromPFI === 'person') return a.person_id === (props.values.fromEntity ? props.values.fromEntity.id : '');
												if (props.values.fromPFI === 'fi') return a.fi_id === (props.values.fromEntity ? props.values.fromEntity.id : '');
												return '';
											}).map(a => <MenuItem key={`from-account-${a.id}`} value={a}>{currencyIdx[a.currency_id]} {a.name}</MenuItem>)];
										}

										let toEntityMenuItems = [];
										if (props.values.toPFI === 'person') {
											toEntityMenuItems = [...aPersons.map(a => <MenuItem key={`person-${a.id}`} value={a}>{a.first_name} {a.last_name} ({a.id}:{a.unique_account_id})</MenuItem>)];
										} else {
											toEntityMenuItems = [...aFis.map(a => <MenuItem key={`fi-${a.id}`} value={a}>{a.name} ({a.id})</MenuItem>)];
										}

										let toAccountMenuItems = [];
										if (props.values.toPFI) {
											toAccountMenuItems = [...aAccounts.filter(a => {
												if (props.values.toPFI === 'person') return a.person_id === (props.values.toEntity ? props.values.toEntity.id : '');
												if (props.values.toPFI === 'fi') return a.fi_id === (props.values.toEntity ? props.values.toEntity.id : '');
												return '';
											}).map(a => <MenuItem key={`to-account-${a.id}`} value={a} disabled={a.currency_id !== props.values.fromAccount.currency_id} >{currencyIdx[a.currency_id]} {a.name}</MenuItem>)];
										}

										return (
											<form>
												<Typography color="textPrimary" variant="h5" align="left">From Account:</Typography>
												<Table>
													<TableBody>
														<TableRow>
															<TableCell>
																<FormLabel component="legend">Account holder type</FormLabel>
															</TableCell>
															<TableCell>
																<RadioGroup required aria-label="person" name="fromPFI" value={props.values.fromPFI} onChange={props.handleChange} row>
																	<FormControlLabel label="Person" value="person" control={<Radio />} />
																	<FormControlLabel label="Financial Institution" value="fi" control={<Radio />} />
																</RadioGroup>
															</TableCell>
														</TableRow>
														<TableRow>
															<TableCell>
																{props.values.fromPFI === 'person' ? <FormLabel component="legend">Person</FormLabel> : ''}
																{props.values.fromPFI === 'fi' ? <FormLabel component="legend">Financial Institution</FormLabel> : ''}
															</TableCell>
															<TableCell>
																<FormControl fullWidth>
																	<Select
																		required
																		value={props.values.fromEntity}
																		onChange={props.handleChange}
																		inputProps={{ name: 'fromEntity', id: 'fromEntity' }}
																		name="fromEntity"
																	>{fromEntityMenuItems}</Select>
																</FormControl>
															</TableCell>
														</TableRow>
														<TableRow>
															<TableCell>
																<FormLabel component="legend">Account</FormLabel>
															</TableCell>
															<TableCell>
																<FormControl fullWidth>
																	<Select
																		required
																		value={props.values.fromAccount}
																		onChange={props.handleChange}
																		inputProps={{ name: 'fromAccount', id: 'fromAccount' }}
																		name="fromAccount"
																	>{fromAccountMenuItems}</Select>
																</FormControl>
															</TableCell>
														</TableRow>
													</TableBody>
												</Table>

												<Typography color="textPrimary" variant="h5" align="left">To Account:</Typography>
												<Table>
													<TableBody>
														<TableRow>
															<TableCell>
																<FormLabel component="legend">Account holder type</FormLabel>
															</TableCell>
															<TableCell>
																<RadioGroup required aria-label="person" name="toPFI" value={props.values.toPFI} onChange={props.handleChange} row>
																	<FormControlLabel label="Person" value="person" control={<Radio />} />
																	<FormControlLabel label="Financial Institution" value="fi" control={<Radio />} />
																</RadioGroup>
															</TableCell>
														</TableRow>
														<TableRow>
															<TableCell>
																{props.values.toPFI === 'person' ? <FormLabel component="legend">Person</FormLabel> : ''}
																{props.values.toPFI === 'fi' ? <FormLabel component="legend">Financial Institution</FormLabel> : ''}
															</TableCell>
															<TableCell>
																<FormControl fullWidth>
																	<Select
																		required
																		value={props.values.toEntity}
																		onChange={props.handleChange}
																		inputProps={{ name: 'toEntity', id: 'toEntity' }}
																		name="toEntity"
																	>{toEntityMenuItems}</Select>
																</FormControl>
															</TableCell>
														</TableRow>
														<TableRow>
															<TableCell>
																<FormLabel component="legend">Account</FormLabel>
															</TableCell>
															<TableCell>
																<FormControl fullWidth>
																	<Select
																		required
																		value={props.values.toAccount}
																		onChange={props.handleChange}
																		inputProps={{ name: 'toAccount', id: 'toAccount' }}
																		name="toAccount"
																	>{toAccountMenuItems}</Select>
																</FormControl>
															</TableCell>
														</TableRow>
													</TableBody>
												</Table>

												<Typography color="textPrimary" variant="h5" align="left">Details:</Typography>
												<Table>
													<TableBody>
														<TableRow>
															<TableCell>
																<FormLabel component="legend">Audit ID</FormLabel>
															</TableCell>
															<TableCell>
																<TextField
																	required
																	fullWidth
																	inputProps={{ type: 'number', min: 0 }}
																	value={props.values.auditId}
																	onChange={props.handleChange}
																	name="auditId"
																></TextField>
															</TableCell>
														</TableRow>
														<TableRow>
															<TableCell>
																<FormLabel component="legend">Description</FormLabel>
															</TableCell>
															<TableCell>
																<TextField
																	required
																	fullWidth
																	value={props.values.description}
																	onChange={props.handleChange}
																	name="description"
																></TextField>
															</TableCell>
														</TableRow>
														<TableRow>
															<TableCell>
																<FormLabel component="legend">Amount</FormLabel>
															</TableCell>
															<TableCell>
																<TextField
																	required
																	fullWidth
																	inputProps={{ type: 'number', min: 0 }}
																	value={props.values.amount}
																	onChange={props.handleChange}
																	name="amount"
																></TextField>
															</TableCell>
														</TableRow>
														<TableRow>
															<TableCell colSpan={2}>
																<Button
																	className={classes.button2}
																	onClick={props.handleSubmit}
																	disabled={!props.isValid}
																>Submit</Button>
															</TableCell>
														</TableRow>
													</TableBody>
												</Table>

											</form>
										);
									}}
								/>
							</Grid>
						</Grid>
					</Paper>
				</Grid>
			</Grid>);
	}
}

export default (inject('routing')(observer(ProductInvestorNew)));