
import React from 'react';
import Header from '../OptionSelector/Header';
import { Grid } from '@material-ui/core';
import AppCard from '../OptionSelector/AppCard';
import Titles from '../OptionSelector/Titles';

function PortfolioOverview(props) {
	const { classes, headerText='N-100', returnsEarnedText='N-30,000', returnsDueText='N-30,000' } = props;
	return (
		<AppCard classes={classes}>
			{Header(undefined, undefined, headerText, 'small')}
			{Titles(undefined, 'Portfolio Balance')}
			<hr />
			<Grid container direction="row" justify="space-between" alignItems="center">
				{Titles(returnsEarnedText, 'Returns Earned', { width: 6, colour: 'green', subTitleFontWeight: 600 })}
				{Titles(returnsDueText, 'Returns Due', { width: 6, subTitleFontWeight: 600 })}
			</Grid>
		</AppCard>
	);
}

export default PortfolioOverview;