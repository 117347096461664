import React from 'react';
import { Grid, Paper, FormControl, InputLabel, Select, MenuItem, Button } from '@material-ui/core'
import { Formik } from 'formik';
import TextField from '@material-ui/core/TextField';
import { inject, observer } from "mobx-react";
import Wysiwyg from '../Wysiwyg/Wysiwyg';

let id = 0;
function nextId() {
	return ++id;
}

class FinancialInstitutionEdit extends React.Component {
	render() {
		const { classes, countries, initialValues } = this.props;
		const self = this;

		async function getImageData(file) {
			return new Promise((resolve) => {
				var fr = new FileReader();
				fr.onload = (event) => resolve(event.target.result)
				fr.readAsDataURL(file);
			});
		}

		return (
			<Grid container key={nextId()}
				direction="row"
				justify="space-evenly"
				alignItems="center"
			>
				<Grid key={nextId()} item xs={12}>
					<Paper className={classes.paper}
						style={{ boxShadow: '0px 20px 40px rgba(0,0,0,0.06)' }}>
						<Grid container spacing={0}>
							<Grid item xs={12}>
								<Formik
									initialValues={initialValues}
									onSubmit={(values, actions) => {
										self.props.onSubmit(values);
									}}
									render={(props) => {
										return (
											<form onSubmit={props.handleSubmit}>
												<div style={{ textAlign: 'right' }}>
													<span>{props.values.id ? `id: ${props.values.id}` : 'new account'}</span>
												</div>
												<InputLabel htmlFor="file">Profile Icon</InputLabel>
												<FormControl
													margin="normal"
													fullWidth
													required
												>
													<div style={{ display: 'flex' }}>
														<div style={{ width: 32, height: 32 }}>
															<img src={props.values.url_logo} alt="" style={{ width: 32, height: 32 }} />
														</div>
														<div id="url_logo_new" style={{ width: 32, height: 32 }}>
															{<img src={props.values.url_logo_new} id="myimage" alt="" style={{ width: 32, height: 32 }} />}
														</div>
														<div>
															<input id="file" name="file" type="file" onChange={async (event) => {
																props.setFieldValue("file", event.currentTarget.files[0]);
																if (event.currentTarget.files.length) {
																	const res = await getImageData(event.currentTarget.files[0]);
																	props.setFieldValue("url_logo_new", res);
																} else {
																	props.setFieldValue("url_logo_new", null);
																}
															}} />
														</div>
													</div>
													<TextField
														className={classes.textFieldContainer}
														InputProps={{ classes: { input: classes.textField } }}
														onChange={props.handleChange}
														onBlur={props.handleBlur}
														value={props.values.url_logo || ''}
														name="url_logo"
														label="Logo URL"
														margin="normal"
														required
														type="text"
														fullWidth
													/>

												</FormControl>

												<TextField
													autoFocus
													className={classes.textFieldContainer}
													InputProps={{ classes: { input: classes.textField } }}
													onChange={props.handleChange}
													onBlur={props.handleBlur}
													value={props.values.name}
													name="name"
													label="Name"
													margin="normal"
													required
													type="text"
													fullWidth
												/>

												<TextField
													className={classes.textFieldContainer}
													InputProps={{ classes: { input: classes.textField } }}
													onChange={props.handleChange}
													onBlur={props.handleBlur}
													value={props.values.url_website || ''}
													name="url_website"
													label="Website URL"
													margin="normal"
													required
													type="text"
													fullWidth
												/>

												<InputLabel htmlFor="profile">Profile</InputLabel>
												<Wysiwyg
													name="profile"
													value={props.values.profile}
													onChange={props.handleChange}
													onBlur={props.handleBlur}
												/>

												<FormControl
													margin="normal"
													fullWidth
													required
												>
													<InputLabel htmlFor="active">Active</InputLabel>
													<Select
														value={props.values.active}
														onChange={props.handleChange}
														onBlur={props.handleBlur}
														name="active"
														label="Active?"
														required
														inputProps={{
															name: 'active',
															id: 'active',
														}}
													>
														<MenuItem value={true}>True</MenuItem>
														<MenuItem value={false}>False</MenuItem>
													</Select>
												</FormControl>

												<FormControl
													margin="normal"
													fullWidth
												>
													<InputLabel htmlFor="country_id">Country</InputLabel>
													<Select
														value={props.values.country_id}
														onChange={props.handleChange}
														onBlur={props.handleBlur}
														name="country_id"
														label="Country"
														required
														inputProps={{
															name: 'country_id',
															id: 'country_id',
														}}
													>
														<MenuItem value=""><em>None</em></MenuItem>
														{countries.map(c => <MenuItem key={`country-${c.id}`} value={c.id}>{c.name}</MenuItem>)}
													</Select>
												</FormControl>

												<Button className={classes.button2} type="submit">Submit</Button>

											</form>
										);
									}}
								/>
							</Grid>
						</Grid>
					</Paper>
				</Grid>
			</Grid>);
	}
}

export default (inject('routing', 'financialInstitutions')(observer(FinancialInstitutionEdit)));