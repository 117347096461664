import { decorate } from 'mobx';
import GenericStore from './GenericStore';

class FinancialInstitutionStore extends GenericStore {
	apiName = 'financial-institutions';
	singular = 'financial-institution';
	plural = 'financial-institutions';

	// constructor() {
	// 	super();
	// 	this.init();
	// }
}

decorate(FinancialInstitutionStore, {
});

export default new FinancialInstitutionStore();
