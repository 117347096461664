import React from 'react';
import Titles from './Titles';
import Header from './Header';
import Lines from './LinesTable';
import AppCard from './AppCard';
import Add from '@material-ui/icons/Add';

export default function OptionSelectWithIcon(props) {
	const { classes, options, onBack, onPlus, subTitle, subTitleText, iconFn, hashVariable, title } = props;

	return (
		<AppCard classes={classes}>
			{Header(onBack, onPlus, title, undefined, undefined, Add)}
			{Titles(subTitle, subTitleText)}
			{Lines(options, props.navigateTo, classes, hashVariable, iconFn)}
		</AppCard>
	);
}