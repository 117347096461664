import { decorate } from 'mobx';
import GenericStore from './GenericStore';

class CountryStore extends GenericStore {
	apiName = 'countries';
	singular = 'country';
	plural = 'countries';
	requiresAuthentication = false;

	// constructor() {
	// 	super();
	// 	this.init();
	// }
}

decorate(CountryStore, {
});

export default new CountryStore();
