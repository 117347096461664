import React from 'react';
import { Grid, Paper, Typography, IconButton, Table, TableBody, TableCell, TableRow } from '@material-ui/core'
import { MenuItem, Select, FormControl } from '@material-ui/core'
import ChevronRight from '@material-ui/icons/ArrowForwardIos';
import ChevronLeft from '@material-ui/icons/ArrowBackIos';

let id = 0;
function nextId() {
	return ++id;
}

export default function (props) {
	const { onBack, subTitle, subTitleText, classes, list, productTypes, filterId = '', navigateTo, hashVariableList, hashVariableFilter } = props;

	return (
		<Grid container key={nextId()}
			spacing={2}
			direction="row"
			justify="space-evenly"
			alignItems="center">
			<Grid key={nextId()} item xs={12}>
				<Paper className={classes.paper}
					style={{ boxShadow: '0px 20px 40px rgba(0,0,0,0.06)' }}>
					<Grid container spacing={0}>

						<Grid item xs={2} align="center">
							{!onBack ? '&nbsp;' :
								<IconButton onClick={() => onBack()}>
									<ChevronLeft />
								</IconButton>
							}
						</Grid>

						<Grid item xs={8}>
							<Typography color="textPrimary" style={{ fontFamily: 'Nunito', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }} variant="h5">
								{props.title}
							</Typography>
						</Grid>

						<Grid item xs={2} align="center">
							&nbsp;
						</Grid>

						<Grid item xs={12}>
							{!subTitle ? '' :
								<Typography style={{ fontSize: 16, fontWeight: 500 }}>
									{subTitle}
								</Typography>
							}
							{!subTitleText ? '' :
								<Typography color="textSecondary" style={{ fontSize: 16, fontWeight: 500, color: 'darkgrey' }}>
									{subTitleText}
								</Typography>
							}
						</Grid>

						<Grid item xs={12}>
							<React.Fragment>

								<FormControl
									style={{ textAlign: 'left' }}
									margin="normal"
									fullWidth
									required>
									<Select
										value={filterId}
										onChange={function (event) {
											navigateTo(hashVariableFilter, event.target.value)
										}}
										name="productUrl"
										label="Product Type"
										required
										inputProps={{
											name: 'productUrl',
											id: 'productUrl',
										}}
									>
										<MenuItem value={null}><em>All</em></MenuItem>
										{productTypes.filter(a=>a.active).map(a => <MenuItem value={a.slug} key={`productTypes-${a.id}`}>{a.name}</MenuItem>)}
									</Select>
								</FormControl>

								<Table width="100%" style={{ color: 'black', fontSize: '14px' }} padding="none">
									{list && list.map((item, idx) => {
										return (
											<TableBody key={`product-${idx}`}>
												<TableRow onClick={() => props.navigateTo(hashVariableList, item.id)}>
													<TableCell><img style={{ height: 32, width: 32 }} alt="" src={item.issuing_fi_url_logo} /></TableCell>
													<TableCell style={{ textAlign: 'left' }}>{item.name}</TableCell>
													<TableCell style={{ textAlign: 'right' }}>{item.interest_rate}%</TableCell>
													<TableCell style={{ textAlign: 'right' }}>{item.tenor} days</TableCell>
													<TableCell style={{ textAlign: 'right' }}>
														<IconButton>
															<ChevronRight className={classes.icon} />
														</IconButton>
													</TableCell>
												</TableRow>
											</TableBody>
										);
									})}
									{(!list || list.length === 0) ? (
										<TableBody>
											<TableRow>
												<TableCell colSpan="5" style={{ textAlign: 'center' }}>
													<i>No products available</i>
												</TableCell>
											</TableRow>
										</TableBody>) : null}
								</Table>

							</React.Fragment>
						</Grid>
					</Grid>
				</Paper>
			</Grid>
		</Grid>);
}