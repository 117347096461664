import React from 'react';
import { urlToHashParams, hashParamsToURL } from '../../libs/common';

export default class SubnavigationSystemRC extends React.Component {
	state = {
		localHistory: [window.location.href.split('?')[0]],
		baseURL: window.location.href.split('?')[0],
		basePath: '',
	}
	config(config){
		this.basePath = config.basePath;
	}
	navigateTo = (key, value) => {
		const hashParams = urlToHashParams(window.location.hash);
		hashParams[key] = value;
		const currentState = hashParamsToURL(this.state.baseURL, hashParams);
		window.history.replaceState(null, null, currentState);
		this.setState({ localHistory: [...this.state.localHistory, currentState] });
	}
	navigateBack = () => {
		if (this.state.localHistory.length) {
			let value = this.state.localHistory.pop();
			if (value === window.location.href) {
				return this.navigateBack();
			}
			window.history.replaceState(null, null, value);
			this.setState({ localHistory: [...this.state.localHistory, value] },
			);
		}
	}
}
