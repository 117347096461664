import React from 'react';
import { Grid, Typography, IconButton } from '@material-ui/core'
import ChevronRight from '@material-ui/icons/ArrowForwardIos';
import ChevronLeft from '@material-ui/icons/ArrowBackIos';

export default function Header(onLeft, onRight, title = 'title', buttonSize = 'small', LeftIcon = ChevronLeft, RightIcon = ChevronRight) {
	return (
		<React.Fragment>
			<Grid item xs={2} align="center">
				{!onLeft ? <IconButton><ChevronLeft style={{ color: 'rgba(0,0,0,0)' }} /></IconButton> :
					<IconButton onClick={() => onLeft()}>
						<LeftIcon fontSize={buttonSize} />
					</IconButton>
				}
			</Grid>

			<Grid item xs={8} align="center">
				<Typography variant="h5" color="textPrimary" style={{ fontFamily: 'Nunito', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
					{title}
				</Typography>
			</Grid>

			<Grid item xs={2} align="center">
				{!onRight ? <IconButton><ChevronRight style={{ color: 'rgba(0,0,0,0)' }} /></IconButton> :
					<IconButton onClick={() => onRight()}>
						<RightIcon fontSize={buttonSize} />
					</IconButton>
				}
			</Grid>
		</React.Fragment>);
}
