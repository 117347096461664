import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import CssBaseline from '@material-ui/core/CssBaseline';
import IconButton from '@material-ui/core/IconButton';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MailIcon from '@material-ui/icons/Mail';

// import logo from '../../images/investor_logo.png';
import MenuIcon from '@material-ui/icons/FormatAlignLeft';
import Badge from '@material-ui/core/Badge';
import NotificationsIcon from '@material-ui/icons/Notifications';
import AccountCircle from '@material-ui/icons/AccountCircle';

import { getPersonTypeMenu } from '../../config/sitemap';
import { withRouter } from "react-router";
import { inject, observer } from "mobx-react";
import { Routes } from '../Routes';
import cssTheme from '../../config/theme';
import logo from '../../images/nobound-logo-v2.png';
import { Link } from "react-router-dom";
const drawerWidth = 240;

const styles = theme => ({
	root: {
		display: 'flex',
		backgroundColor: '#ffffff',
	},
	grow: {
		flexGrow: 1,
	},
	appBar: {
		width: `calc(100% - ${40 + 16 + 16}px)`,
		zIndex: theme.zIndex.drawer + 1,
		transition: theme.transitions.create(['width', 'margin'], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
		boxShadow: '0px 6px 20px rgba(0,0,0,0.1)',
		backgroundColor: '#ffffff',
		borderLeft: '1px solid rgba(0, 0, 0, 0.12);',
	},
	appBarShift: {
		marginLeft: drawerWidth,
		width: `calc(100% - ${drawerWidth}px)`,
		transition: theme.transitions.create(['width', 'margin'], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
		boxShadow: '0px 6px 20px rgba(0,0,0,0.1)',
		backgroundColor: '#ffffff',
		border: '1px solid #e5e8ee',
	},
	menuButton: {
		marginLeft: 12,
		marginRight: 36,
	},
	hide: {
		display: 'none',
	},
	drawer: {
		width: drawerWidth,
		flexShrink: 0,
		whiteSpace: 'nowrap',
		boxShadow: '0 0 15px rgba(0,0,0,0.06)',
	},
	drawerOpen: {
		width: drawerWidth,
		transition: theme.transitions.create('width', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
	},
	drawerClose: {
		transition: theme.transitions.create('width', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
		overflowX: 'hidden',
		width: theme.spacing(7) + 1,
		[theme.breakpoints.up('sm')]: {
			width: theme.spacing(9) + 1,
		},
	},
	toolbar: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-end',
		padding: '0 8px',
		height: 64,
		// ...theme.mixins.toolbar,
	},
	content: {
		flexGrow: 1,
		// padding: theme.spacing(3),
		backgroundColor: '#f8f8f8',
	},
	buttonRoot: {
		color: '#91969f',
		backgroundColor: '#f4f4f4',
		marginRight: 16
	},
	badgeMail: {
		top: -8,
		left: 4,
		width: 18,
		height: 18,
		backgroundColor: cssTheme.primaryCTAColour,
		color: cssTheme.primaryCTATextColour,
	},
	badgeNotification: {
		top: -8,
		left: 4,
		width: 18,
		height: 18,
		backgroundColor: 'black',
		color: cssTheme.primaryCTATextColour,
	},
	divider: {
		display: 'inline-block',
		border: '1px solid #e5e8ee',
		paddingLeft: 32,
		borderTop: 0,
		borderBottom: 0,
		marginRight: 32,
		paddingRight: 16,
	},
	listText: {
		color: '#acb1b9',
		// height: 32,
	}
});

class AppShell extends React.Component {
	state = {
		open: false,
	};
	constructor(props) {
		super(props);
		this.props.session.checkLogin();
	}
	handleDrawerOpen = () => {
		this.setState({ open: true });
	};

	handleDrawerClose = () => {
		this.setState({ open: false });
	};
	sideList(classes, sitemap, isAdmin) {
		const { push } = this.props.routing;
		const list = [];
		sitemap.pages.sort((a, b) => a.order - b.order).forEach((page, idx) => {
			if (!page.menu && !isAdmin) return null;
			list.push((
				<ListItem
					data-menu-selected={this.props.routing.location.pathname === page.path}
					alignItems="center"
					button
					key={idx}
					onClick={() => { push(page.path) }}>
					{page.icon ? <ListItemIcon classes={{ root: classes.listText }} >{page.icon}</ListItemIcon> : ''}
					<ListItemText
						primary={page.text}
						classes={{ primary: classes.listText }} />
				</ListItem>
			));

		});
		return <List>{list}</List>;
	}
	render() {
		const { classes, theme, session, notifications } = this.props;
		const isAuthenticated = session.isAuthenticated;
		const sitemap = getPersonTypeMenu(session.person);
		const isAdmin = session.isAdmin;

		return (
			<div className={classes.root}>
				<CssBaseline />
				<style>{`
					body,html {background-color:#f8f8f8;}
					div[data-menu-selected="true"]::before,div[data-menu-selected="true"]:focus::before {
						background: rgb(130,202,62);
					}
				`}</style>
				<AppBar
					position="fixed"
					className={classNames(classes.appBar, {
						[classes.appBarShift]: this.state.open,
					})}>
					<Toolbar disableGutters={!this.state.open}>
						<Link to="/"><img alt="nobound invest logo" src={logo} style={{ height: 50, marginLeft: `${theme.spacing(3)}px` }} /></Link>
						<div className={classes.grow} />
						{isAuthenticated &&
							<div className={classes.sectionDesktop}>
								<div className={classes.divider}>
									<IconButton color="primary" className={classNames(classes.buttonRoot)} onClick={() => notifications.clearMails()}>
										<Badge badgeContent={notifications.unreadMailCount} color="primary" classes={{ badge: classes.badgeMail }}>
											<MailIcon fontSize="small" classes={{ colorPrimary: '#91969f' }} />
										</Badge>
									</IconButton>
									<IconButton color="primary" className={classNames(classes.buttonRoot)} onClick={() => notifications.clearNotifications()}>
										<Badge badgeContent={notifications.unreadNotificationCount} color="secondary" classes={{ badge: classes.badgeNotification }}>
											<NotificationsIcon fontSize="small" />
										</Badge>
									</IconButton>
								</div>
								<IconButton
									aria-haspopup="true"
									color="primary"
									style={{ border: '1px solid rgba(20,133,255,0.5)' }}
									className={classNames(classes.buttonRoot)}
								>
									<AccountCircle />
								</IconButton>
							</div>}
					</Toolbar>
				</AppBar>
				<Drawer
					variant="permanent"
					className={classNames(classes.drawer, {
						[classes.drawerOpen]: this.state.open,
						[classes.drawerClose]: !this.state.open,
					})}
					classes={{
						paper: classNames({
							[classes.drawerOpen]: this.state.open,
							[classes.drawerClose]: !this.state.open,
						}),
					}}
					open={this.state.open}
				>
					<div className={classes.toolbar}>
						<IconButton
							aria-label="Open drawer"
							onClick={this.state.open ? this.handleDrawerClose : this.handleDrawerOpen}>
							<MenuIcon />
						</IconButton>
					</div>
					{isAuthenticated && this.sideList(classes, sitemap, isAdmin)}
				</Drawer>
				<main className={classes.content}>
					<div className={classes.toolbar} />
					<Routes sitemap={sitemap} isAuthenticated={isAuthenticated} />
				</main>
			</div>
		);
	}
}

AppShell.propTypes = {
	classes: PropTypes.object.isRequired,
	theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(withRouter(inject('routing', 'session', 'notifications')(observer(AppShell))));
