import React from 'react';
import { Grid, Paper, Typography, Button, IconButton } from '@material-ui/core'
import { Table, TableBody, TableRow, TableCell } from '@material-ui/core';
import ChevronLeft from '@material-ui/icons/ArrowBackIos';

import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

let id = 0;
function nextId() {
	return ++id;
}

export default function (props) {
	const { classes, onBack, title, onSubmit, onAddFunds } = props;
	const { amount, symbol, tenor, earnings, interest_rate, cashBalanceBefore, cashBalanceAfter, product, notEnoughCash, amountRaw } = props.data;
	const noTermsText = 'No terms here!';

	return (
		<Grid container key={nextId()}
			spacing={2}
			direction="row"
			justify="space-evenly"
			alignItems="center"
		>
			<Grid key={nextId()} item xs={12} sm={3} md={3} lg={3}>
				<Paper className={classes.paper}
					style={{ boxShadow: '0px 20px 40px rgba(0,0,0,0.06)' }}>
					<Grid container spacing={0}>
						<Grid item xs={2} align="center">
							{!onBack ? '&nbsp;' :
								<IconButton onClick={() => onBack()}>
									<ChevronLeft />
								</IconButton>
							}
						</Grid>

						<Grid item xs={8}>
							<Typography variant="h5" color="textPrimary" style={{ fontFamily: 'Nunito', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
								{title}
							</Typography>
						</Grid>

						<Grid item xs={2} align="center">
							&nbsp;
						</Grid>

						<Grid item xs={12}>
							<Table>
								<TableBody>
									<TableRow>
										<TableCell style={{ paddingLeft: '0px', paddingRight: '0px' }}><Typography variant="body1">You're investing:</Typography></TableCell>
										<TableCell align="right" style={{ fontWeight: 'bold', fontSize: 'initial' }}>{symbol} {amount}</TableCell>
									</TableRow>
									<TableRow>
										<TableCell style={{ paddingLeft: '0px', paddingRight: '0px' }}><Typography variant="body1">For a period of:</Typography></TableCell>
										<TableCell align="right" style={{ fontWeight: 'bold', fontSize: 'initial' }}>{tenor} days</TableCell>
									</TableRow>
									<TableRow>
										<TableCell style={{ paddingLeft: '0px', paddingRight: '0px' }}><Typography variant="body1">At a rate of:</Typography></TableCell>
										<TableCell align="right" style={{ fontWeight: 'bold', fontSize: 'initial' }}>{interest_rate}%</TableCell>
									</TableRow>
									<TableRow>
										<TableCell style={{ paddingLeft: '0px', paddingRight: '0px' }}><Typography variant="body1">You will earn:</Typography></TableCell>
										<TableCell align="right" style={{ fontWeight: 'bold', fontSize: 'initial' }}>{symbol} {earnings}</TableCell>
									</TableRow>
									<TableRow>
										<TableCell colSpan={2}><Typography color="textSecondary" variant="body1">The funds to invest will be taken from your cash balance as follows:</Typography></TableCell>
									</TableRow>
									<TableRow>
										<TableCell style={{ paddingLeft: '0px', paddingRight: '0px' }}><Typography variant="body1">Balance before:</Typography></TableCell>
										<TableCell align="right" style={{ fontWeight: 'bold', fontSize: 'initial' }}>{symbol} {cashBalanceBefore}</TableCell>
									</TableRow>
									<TableRow>
										<TableCell style={{ paddingLeft: '0px', paddingRight: '0px', color: (notEnoughCash ? 'red' : '') }}><Typography variant="body1">Balance after:</Typography></TableCell>
										<TableCell align="right" style={{ fontWeight: 'bold', fontSize: 'initial', color: (notEnoughCash ? 'red' : '') }}>{symbol} {cashBalanceAfter}</TableCell>
									</TableRow>
									{
										notEnoughCash === false ? null : (
											<TableRow>
												<TableCell style={{ color: (notEnoughCash ? 'red' : '') }} colSpan={2} align="center">
													Not enough funds for this investment<br/>
													<Button 
														className={classes.button2}
														onClick={onAddFunds}>Go to Add Funds</Button>
												</TableCell>
											</TableRow>
										)
									}
								</TableBody>
							</Table>

							<ExpansionPanel style={{ marginBottom: 16 }}>
								<ExpansionPanelSummary
									expandIcon={<ExpandMoreIcon />}
									aria-controls="panel1a-content"
									id="panel1a-header">
									<Typography className={classes.heading}>Terms and Conditions</Typography>
								</ExpansionPanelSummary>
								<ExpansionPanelDetails>
									<Typography style={{ textAlign: 'left' }} dangerouslySetInnerHTML={{ __html: product.terms_and_conditions || noTermsText }} />
								</ExpansionPanelDetails>
							</ExpansionPanel>

							<Button
								disabled={notEnoughCash}
								className={notEnoughCash?null:classes.button2}
								onClick={() => onSubmit({ product_id: product.id, amount: amountRaw, terms_and_conditions: true })}
							>Commit</Button>
						</Grid>
					</Grid>
				</Paper>
			</Grid>
		</Grid>);
}