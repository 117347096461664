import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import AccountOverview from './AccountsOverview';
import { inject, observer } from "mobx-react";

import HUE from '@material-ui/core/colors/grey';
import configTheme from '../../config/theme';
import { numberWithCommas, calculatePortfolioTotals, formatValue } from '../../libs/common';

import { Progress1 } from '../ProgressIndicators';

const accountTileNameText = HUE[400];

const styles = theme => ({
	menuButton: {
		marginLeft: 12,
		marginRight: 12,
	},
	paper: {
		padding: theme.spacing(2),
		textAlign: 'center',
		color: accountTileNameText,
	},
	button1: configTheme.darkButton,
	button2: configTheme.lightButton,
});

class AccountsOverviewContainer extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			selectedAccount: 0
		};
	}

	onSendFundsClick() {
		this.props.routing.push('wallet');
	}

	onUpdateBalances = () => {
		this.props.accounts.check();
	}
	navigateToAccountActions = () => {

	}
	async componentDidMount(){
		await this.props.currencies.check();
		await this.props.portfolio.check();
		await this.props.accounts.check();
	}

	render() {
		const { classes, accounts, currencies, portfolio, session } = this.props;
		if (session.isBusy) return <Progress1 text="Session"/>;
		if (accounts.isBusy) return <Progress1 text="Accounts"/>;
		if (currencies.isBusy) return <Progress1 text="Currencies"/>;
		if (portfolio.isBusy) return <Progress1 text="Portfolio"/>;
		if (!accounts.data || !accounts.data.length) return <Progress1 text="Account Data"/>;;

		const portfolioTotals = calculatePortfolioTotals(portfolio.data);

		let accountsData = [];

		// investment card
		let account = {
			currencySymbol: '₦',
			value: numberWithCommas(formatValue(portfolioTotals.returnsTotal)),
			decimalValue: '',
			name: 'Portfolio Balance',
			onClick: () => this.props.routing.push('invest'),
			onTitleClick: () => this.props.routing.push('portfolio'),
		};
		let accountMeta = {
			buttonText: 'Invest',
			buttonClass: classes.button2,
		};
		accountsData.push(Object.assign({}, account, accountMeta));

		// account cards
		if (accounts.data) {
			let myAccounts = accounts.data;
			myAccounts.forEach(myAccount => {

				let account = {
					currencySymbol: currencies.idxById[myAccount.currency_id].symbol,
					value: numberWithCommas(formatValue(myAccount.cash_balance)),
					decimalValue: formatValue(myAccount.cash_balance, 'f'),
					name: 'Cash Balance',
					onClick: () => this.props.routing.push('account-actions#?operation=add-funds'),
					onTitleClick: () => this.props.routing.push('account-actions'),
				};
				let accountMeta = {
					buttonText: 'Add funds',
					buttonClass: classes.button1,
				};
				accountsData.push(Object.assign({}, account, accountMeta));
			});
		}

		const onNextAccount = () => {
			this.setState({ selectedAccount: this.state.selectedAccount === 0 ? 1 : 0 });
		}

		return <AccountOverview accountsData={accountsData} classes={classes} selectedAccount={this.state.selectedAccount} onNextAccount={onNextAccount} />;
	}
}

export default withStyles(styles, { withTheme: true })(inject('routing', 'session', 'accounts', 'currencies', 'portfolio')(observer(AccountsOverviewContainer)));
