import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { OptionSelector } from '../OptionSelector';
import { inject, observer } from "mobx-react";

import { urlToHashParams } from '../../libs/common';
import HUE from '@material-ui/core/colors/grey';
import configTheme from '../../config/theme';
import SubNavigationSystemRC from './SubNavigationSystemRC';

const accountTileNameText = HUE[400];

const styles = theme => ({
	menuButton: {
		marginLeft: 12,
		marginRight: 12,
	},
	paper: {
		padding: theme.spacing(2),
		textAlign: 'center',
		color: accountTileNameText,
	},
	button1: configTheme.lightTrypticButton,
	button2: configTheme.lightButton,
	icon: {
		color: 'rgb(130,202,64)'
	},
});

class TermsContainer extends SubNavigationSystemRC {
	constructor(props) {
		super(props);
		super.config({ basePath: 'content' });
	}

	async componentDidMount() {
		return Promise.all(['content'].map(a => this.props[a].check()));
	}

	render() {
		const { classes } = this.props;
		const hashParams = urlToHashParams(window.location.hash);

		const options = [
			{ id: 1, slug: 'about-us', name: 'About Us', active: true },
			{ id: 2, slug: 'terms', name: 'Terms & Conditions', active: true },
			{ id: 3, slug: 'privacy-policy', name: 'Privacy Policy', active: true },
		];
		if (!hashParams.page) {
			const title = 'Nobound Solutions';
			const subTitle = 'By using this application, you are agreeing to our usage terms';

			return <OptionSelector classes={classes} options={options} title={title} subTitle={subTitle} navigateTo={this.navigateTo} hashVariable="page" onBack={null} />;
		}

		if (hashParams.page) {
			let content = this.props.content.data.filter(a=>a.data.slug===hashParams.page)[0] || `<p>no content found for key "${hashParams.page}"</p>`;
			
			const {html, title, subTitle} = content.data;
			return <OptionSelector classes={classes} onBack={this.navigateBack} html={html} title={title} subTitle={subTitle} />;
		}

		return null;
	}
}

export default withStyles(styles, { withTheme: true })(inject('content')(observer(TermsContainer)));
