import { decorate } from 'mobx';
import GenericStore from './GenericStore';

class SettlementAccountStore extends GenericStore {
	apiName = 'settlement-account';
	singular = 'settlement-account';
	plural = 'settlement-accounts';

	// constructor() {
	// 	super();
	// 	this.init();
	// }
	getInitialValues = (id) => {
		let initialValues = {
			id: 0,
			name: '',
			person_id: 0,
			country_id: '',
			currency_id: '',
			account_number: '',
			sort_code: '',
			iban: '',
			swift_bic: '',
			aba_routing: '',
			active: false,
		};
		if (id) {
			const item = this.data.find(item => item.id === id);
			if (item) {
				initialValues = Object.assign({}, item);
			}
		}
		return initialValues;
	}
}

decorate(SettlementAccountStore, {
});

export default new SettlementAccountStore();
