import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { OptionSelector } from '../OptionSelector';
import { inject, observer } from "mobx-react";

import { urlToHashParams, hashParamsToURL } from '../../libs/common';
import HUE from '@material-ui/core/colors/grey';
import configTheme from '../../config/theme';

const accountTileNameText = HUE[400];

const styles = theme => ({
	menuButton: {
		marginLeft: 12,
		marginRight: 12,
	},
	paper: {
		padding: theme.spacing(2),
		textAlign: 'center',
		color: accountTileNameText,
	},
	button1: configTheme.lightTrypticButton,
	button2: configTheme.lightButton,
	icon: {
		color: 'rgb(130,202,64)'
	},
});

class InvestContainer extends React.Component {
	state = {
		localHistory: [window.location.href.split('?')[0]],
		baseURL: window.location.href.split('?')[0],
		basePath: 'invest',
	}

	navigateTo = (key, value) => {

		const hashParams = urlToHashParams(window.location.hash);

		hashParams[key] = value;

		const currentState = hashParamsToURL(this.state.baseURL, hashParams);

		window.history.replaceState(null, null, currentState);
		this.setState({ localHistory: [...this.state.localHistory, currentState] },
		);
	}
	navigateBack = () => {

		if (this.state.localHistory.length) {
			let value = this.state.localHistory.pop();

			if (value === window.location.href) {
				return this.navigateBack();
			}

			window.history.replaceState(null, null, value);
			this.setState({ localHistory: [...this.state.localHistory, value] },
			);
		} else {
		}
	}

	async componentDidMount() {
		return Promise.all(['propductTypes'].map(a => this.props[a].check()));
	}

	render() {
		const { classes, propductTypes } = this.props;
		const hashParams = urlToHashParams(window.location.hash);

		if (!hashParams.productType) {
			const title = 'Products';
			const subTitle = 'Select a product type';

			return <OptionSelector classes={classes} onBack={this.navigateBack} options={propductTypes.data} title={title} subTitle={subTitle} navigateTo={this.navigateTo} hashVariable="product-type" />;
		}

		return null;
	}
}

export default withStyles(styles, { withTheme: true })(inject('routing', 'session', 'notifications', 'propductTypes')(observer(InvestContainer)));
