import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { inject, observer } from 'mobx-react';
import { AppCard } from '../OptionSelector';
import { Typography } from '@material-ui/core';
import MailUnread from '@material-ui/icons/MailTwoTone';

function MessageSummary(props) {
	const fontWeight = props.data.date_read ? 'inherit' : 'bold';
	return (
		<tbody onClick={() => props.data.message && props.onClick(props.data)}>
			<tr>
				<td style={{ width: '80%', textAlign: 'left' }}>
					<Typography style={{ fontWeight, display: 'flex', justifyContent: 'space-between' }} variant="h6">
						{props.data.title || null}
						{props.data.date_read ? null : <MailUnread />}
					</Typography>
				</td>
				{props.data.date_created &&
					<td style={{ width: '20%', textAlign: 'right', fontWeight }}>
						{new Date(props.data.date_created).toString().substring(4, 10)}
					</td>}
			</tr>
			<tr>
				<td colSpan={2}>
					<Typography variant="body1" align="left" dangerouslySetInnerHTML={{ __html: props.data.message || null }}></Typography>
					<hr />
				</td>
			</tr>

		</tbody>
	);
}
const noData = {
	title: 'No Messages'
};

class MessageContainer extends React.Component {
	componentDidMount() {
		this.props.messages.check();
	}
	render() {
		let data = [];
		if (this.props.messages.data && this.props.messages.data.length) {
			data = this.props.messages.data.map(a => Object.assign({}, a)).sort((a, b) => b.id - a.id);
		}

		return (
			<AppCard classes={this.props.classes}>
				<table style={{ width: '100%' }}>
					{data.map((a, key) => <MessageSummary key={key} data={a} onClick={this.props.messages.markAsRead} />)}
					{data.length === 0 && <MessageSummary data={noData} />}
				</table>
			</AppCard>
		);
	}
}

const styles = theme => ({
	container: {
		display: 'flex',
		flexWrap: 'wrap',
	},
	paper: {
		boxShadow: '0px 20px 40px rgba(0,0,0,0.06)',
		padding: theme.spacing(2),
		textAlign: 'center'
	},
	card: {
		minWidth: '90%',
		height: 140,
		margin: theme.spacing(1),
		textTransform: 'capitalize',
	},
	logoutCard: {
		minWidth: '95%',
		// height: 35,
		margin: theme.spacing(1),
		textTransform: 'capitalize',
	},

});

export default withStyles(styles, { withTheme: true })(inject('messages')(observer(MessageContainer)));
