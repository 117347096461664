
export const interfacePersonProperties /*extends DBEntityProperties*/ = {
	id: 'number',
	country_of_origin_id: 'number',
	country_of_residence_id: 'number',
	sub: 'string',
	email: 'string',
	person_type_id: 'number',
	first_name: 'string',
	last_name: 'string',
	isKYCValid: 'boolean', // ?
	date_of_birth: 'Date', // ?
	isInvestor: 'boolean', // ?
	isStaff: 'boolean', // ?
	isSuper: 'boolean', // ?
	isFiUser: 'boolean', // ?
	mobile_number: 'string', // ?
	mobile_country_id: 'number', // ?
	address: 'string',
};

export default class Person {

	_schema = interfacePersonProperties;

	constructor(data) {
		let p;
		for (p in this._schema) {
			this[p] = '';
			switch (this._schema[p]) {
				case 'number':
					this[p] = isNaN(parseInt(data[p])) ? '' : parseInt(data[p]);
					break;
				case 'string':
					this[p] = '' + data[p];
					break;
				case 'boolean':
					this[p] = typeof (data[p]) === 'boolean' ? data[p] : false;
					break;
				case 'Date':
					if ([null, undefined, ''].indexOf(data[p]) >= 0) {
						this[p] = '';
					} else if (data[p] instanceof Date && data[p].toString() !== 'Invalid Date') {
						this[p] = data[p].toISOString().substr(0, 10);
					} else if (new Date(data[p]).toString() !== 'Invalid Date') {
						this[p] = new Date(data[p]).toISOString().substr(0, 10);
					} else {
						this[p] = '';
					}
					break;
				default:
					break;
			}
		}
		return this;
	}
}