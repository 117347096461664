import { decorate } from 'mobx';
import GenericStore from './GenericStore';

class ContentStore extends GenericStore {
	apiName = 'content';
	singular = 'content';
	plural = 'content';
}

decorate(ContentStore, {
});

export default new ContentStore();
