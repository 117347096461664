import React from 'react';
import ReactDOM from 'react-dom';
import { AppShell, AppShellMobile } from './components/AppShell';
import * as serviceWorker from './serviceWorker';
import { Provider } from "mobx-react";
import { createBrowserHistory } from "history";
import { syncHistoryWithStore } from "mobx-react-router";
import { Router } from "react-router";
import ScrollToTop from "./components/ScrollToTop/ScrollToTop";
import Hidden from '@material-ui/core/Hidden';
import withWidth from '@material-ui/core/withWidth';
import {
	routingStore, sessionStore, notificationStore, currencyStore, financialInstitutionStore, countryStore,
	productStore, productTypeStore, portfolioStore, accountStore, personStore, transactionStore, messageStore,
	settlementAccountStore, contentStore
} from './stores';
import Amplify from "aws-amplify";
import config from "./config/config";
import { MessageSnackbar } from './components/MessageSnackbar';

const endpoints = [
	"messages", "currencies", "countries", "financial-institutions", "person", "products",
	"portfolio", "accounts", "transaction", "product-types", "settlement-account", "notifications", "content"
];

Amplify.configure({
	Auth: {
		mandatorySignIn: true,
		region: config.cognito.REGION,
		userPoolId: config.cognito.USER_POOL_ID,
		identityPoolId: config.cognito.IDENTITY_POOL_ID,
		userPoolWebClientId: config.cognito.APP_CLIENT_ID
	},
	Storage: {
		region: config.s3.REGION,
		bucket: config.s3.BUCKET,
		identityPoolId: config.cognito.IDENTITY_POOL_ID,
		level: "private"
	},
	API: {
		endpoints: endpoints.map(a => ({
			name: a,
			endpoint: config.apiGateway.URL,
			service: 'lambda',
			region: config.apiGateway.REGION
		}))
	}
});

const browserHistory = createBrowserHistory();
const stores = {
	routing: routingStore,
	session: sessionStore,
	notifications: notificationStore,
	currencies: currencyStore,
	financialInstitutions: financialInstitutionStore,
	countries: countryStore,
	products: productStore,
	productTypes: productTypeStore,
	portfolio: portfolioStore,
	accounts: accountStore,
	persons: personStore,
	transactions: transactionStore,
	messages: messageStore,
	settlementAccounts: settlementAccountStore,
	content: contentStore,
};
const history = syncHistoryWithStore(browserHistory, routingStore);

function shell() {
	return (
		<React.Fragment>
			<Hidden implementation="js" only={['md', 'lg', 'xl']}>
				<AppShellMobile />
			</Hidden>
			<Hidden implementation="js" only={['xs', 'sm']}>
				<AppShell />
			</Hidden>
			<MessageSnackbar />
		</React.Fragment>
	);
}
const ComposedShell = withWidth()(shell);

const app = (
	<Provider {...stores}>
		<Router history={history}>
			<ScrollToTop location={history.location}>
				<ComposedShell />
			</ScrollToTop>
		</Router>
	</Provider>
);


ReactDOM.render(app, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
