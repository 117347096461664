import React from 'react';
import { inject, observer } from "mobx-react";
import { AccountTransactionsUI } from './';

class AccountTransactionsUIContainer extends React.Component {
	async componentDidMount(){
		await this.props.transactions.check(this.props.account.id);
	}
	render(){
		return <AccountTransactionsUI {...this.props} />;
	}
}

export default (inject('routing', 'accounts', 'currencies', 'settlementAccounts', 'transactions')(observer(AccountTransactionsUIContainer)));