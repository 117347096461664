import { observable, action, decorate } from 'mobx';
import { Auth, API } from "aws-amplify";
import MessageTypes from './models/MessageTypes';
import { timestamp } from '../libs/common';

class NotificationStore {
	isBusy = false;
	unreadMailCount = 0;
	unreadNotificationCount = 0;
	notificationIntervalPtr = 0;
	apiName = 'notifications';
	singular = 'message';
	plural = 'messages';
	messageQueue = [];

	constructor() {
		this.startNotificationchecker();
		this.startNotificationListener();
	}

	setIsBusy(isBusy) {
		this.isBusy = isBusy;
	}

	startNotificationListener = () => {
		window.addEventListener('nbmessage', event => {
			this.addToMessageQueue(event.detail);
		});
	}
	addToMessageQueue = (message) => {
		this.messageQueue.push(message);
	}
	popFromMessageQueue = () => {
		this.messageQueue.pop();
	}
	getFromMessageQueue = () => {
		if (!this.messageQueue.length) return null;
		return this.messageQueue[0];
	}

	startNotificationchecker = () => {
		let checkNotifications = this.check;
		window.setTimeout(() => {
			checkNotifications();

			// only activate the interval once
			if (!this.notificationIntervalPtr) {
				// this.notificationIntervalPtr = window.setInterval(checkNotifications, 60000);
			}
		}, 1000);
	}

	setMailCount(value) {
		this.unreadMailCount = value;
	}

	setNotificationCount(value) {
		this.unreadNotificationCount = value;
	}

	async getInit() {
		const user = await Auth.currentAuthenticatedUser();
		const token = user.signInUserSession.idToken.jwtToken;
		return {
			headers: { Authorization: token },
			response: true,
			body: {}
		};
	}

	//@action.bind
	clearMails() {
		this.setMailCount(0);
	}
	//@action.bind
	clearNotifications() {
		this.setNotificationCount(0);
	}
	//@action.bind
	check = async () => {
		let path = `/${this.plural}`;
		let myInit = await this.getInit();
		API.get(this.apiName, path, myInit).then(response => {
			const messageTypes = {
				[MessageTypes.NOTIFICATION]: 0,
				[MessageTypes.MAIL]: 0,
			};
			if (response.data && response.data.message) {
				response.data.message.forEach(msg => messageTypes[msg.message_type.id]++);
			}
			this.setMailCount(messageTypes[MessageTypes.MAIL]);
			this.setNotificationCount(messageTypes[MessageTypes.NOTIFICATION]);
		}).catch(error => {
			console.log(error);
		});
	}
	//@action.bind
	send = async (currency) => {
		let path = `/${this.singular}`;
		let myInit = await this.getInit();
		myInit.body.message = `I have transfered ${currency} to a Nobound account`;
		return API.post(this.apiName, path, myInit).then(response => {
			if (response.data.rowCount === 1) {
				alert('Funding Notice Received. Agent Notified');
				return true;
			} else {
				alert('Funding Notice Error.');
				return false;
			}
		}).catch(error => {
			console.log(error);
			return false;
		});
	}
	sendOther = async (message) => {
		let path = `/${this.singular}`;
		let myInit = await this.getInit();
		myInit.body.message = message;
		return API.post(this.apiName, path, myInit).then(response => {
			return true;
		}).catch(error => {
			console.log(error);
			return false;
		});
	}
	//@action.bind
	markAsRead = async (messageId) => {
		this.markAs(messageId, timestamp());
	}
	//@action.bind
	markAsUnread = async (messageId) => {
		this.markAs(messageId, false);
	}
	//@action.bind
	markAs = async (messageId, value) => {
		let path = `/${this.singular}/${messageId}`;
		let myInit = await this.getInit();
		myInit.body.date_read = value;

		API.patch(this.apiName, path, myInit).then(response => {
			console.log(response);
		}).catch(error => {
			console.log(error);
		});
	}
}

decorate(
	NotificationStore, {
	unreadNotificationCount: observable,
	unreadMailCount: observable,
	isBusy: observable,
	messageQueue: observable,

	clearMails: action,
	clearNotifications: action,
	startNotificationchecker: action,
	setMailCount: action,
	setNotificationCount: action,
	addToMessageQueue: action,
	popFromMessageQueue: action,

	send: action,
	check: action,
	markAs: action,
	markAsRead: action,
	markAsUnread: action,
}
)

export default new NotificationStore();
