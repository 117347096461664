import { action, decorate, runInAction, observable } from 'mobx';
import GenericStore from './GenericStore';

class AccountStore extends GenericStore {
	apiName = 'accounts';
	singular = 'account';
	plural = 'accounts';
	isExchangeSubmitting = false;

	findUsersCurrencyAccount = (person_id, currency_id) => {
		if (!person_id) {
			const msg = `findUsersCurrencyAccount: person_id was set to ${person_id}`;
			console.log(msg);
			throw new Error(msg)
		}
		if (!currency_id) {
			const msg = `findUsersCurrencyAccount: currency_id was set to ${currency_id}`
			console.log(msg);
			throw new Error(msg)
		}
		let account = this.data.filter(account => account.person_id === person_id && account.currency_id === currency_id)[0] || null;
		return account;
	}

	withdraw = async ({ account, amount, settlementAccount }) => {
		if (amount < 1) {
			const msg = `withdraw: amount must be >= 1`;
			console.log(msg);
			throw new Error(msg)
		}

		if (amount > account.cash_balance) {
			const msg = `insufficient funds`;
			console.log(msg);
			throw new Error(msg)
		}

		let path = `/${this.singular}/${account.id}/withdraw`;
		let message = await this.getInitialisedMessage();
		Object.assign(message.body, { amount, settlementAccountId: settlementAccount.id });
		runInAction(() => {
			this.isBusy = true;
		})

		return this.API.post(this.apiName, path, message).then(response => {
			this.isBusy = false;
			this.check();
			this.sendNotification(`Withdraw Request Sent`, 'success');
			return true;

		}).catch(error => {
			this.isBusy = false;
			this.processErrorCode(error);
			return false;

		});

	}

	doExchange = async ({ accountFromId, accountToId, currencyIdFrom, currencyIdTo, amountFrom, amountTo, rateId }) => {
		if (amountFrom <= 0) throw new Error(`Amount to exchange must be > 0`);
		if (amountTo <= 1) throw new Error(`Amount to exchange to must be > 1`);

		this.isBusy = true;
		this.isExchangeSubmitting = true;
		let path = `/${this.singular}/${accountFromId}/exchange`;
		let message = await this.getInitialisedMessage();
		Object.assign(message.body, { accountToId, currencyIdFrom, currencyIdTo, amountFrom, amountTo, rateId });

		return this.API.post(this.apiName, path, message).then(response => {
			this.isBusy = false;
			this.isExchangeSubmitting = false;
			this.check();
			this.sendNotification(`Exchange Request Sent`, 'success');
			return true;

		}).catch(error => {
			this.isBusy = false;
			this.isExchangeSubmitting = false;
			this.processErrorCode(error);
			return false;
		});
	}
}

decorate(AccountStore, {
	isExchangeSubmitting: observable,

	doExchange: action,
	findUsersCurrencyAccount: action,
});

export default new AccountStore();
