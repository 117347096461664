import React, { useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Grid, Typography, Button, TextField, MenuItem, FormControl, Link } from '@material-ui/core'
import configTheme from '../../config/theme';
import AppCard from '../OptionSelector/AppCard';
import { Header } from '../OptionSelector';

function WithdrawUI(props) {
	const { classes, onBack, account, person } = props;
	let [amount, amountSet] = useState(0);
	let [settlementAccount, settlementAccountSet] = useState(0);
	let options = [];

	// setup settlementAccounts dropdown
	if (props.settlementAccounts && props.settlementAccounts.length > 0) {
		options = props.settlementAccounts.map(a => ({ value: a, label: a.name }));
		if (options.length === 1 && settlementAccount === 0) {
			settlementAccountSet(options[0].value);
		}
	}

	// get account details


	return (
		<AppCard classes={classes}>
			{Header(onBack, null, 'Withdraw')}
			<Grid item xs={12}>
				<Typography variant="h6" align="center">Balance: {props.balance}</Typography>
			</Grid>

			<Grid item xs={12}>
				<FormControl
					margin="normal"
					fullWidth
					required
				>
					<TextField
						autoComplete="off"
						id="outlined-select-currency"
						label="Amount"
						className={classes.textField}
						value={amount}
						onChange={a => amountSet(parseInt(a.target.value))}
						margin="normal"
						variant="outlined"
						InputProps={{
							type: 'number',
							inputProps: {
								min: 0,
								max: props.amount || 0,
							}
						}}>
					</TextField>
				</FormControl>
			</Grid>

			<Grid item xs={12}>
				{options.length>0 &&
					<FormControl
						margin="normal"
						fullWidth
						required
					>
						<TextField
							id="outlined-select-currency"
							select
							label="Send to Settlement Account"
							className={classes.textField}
							value={settlementAccount}
							onChange={(a) => settlementAccountSet(a.target.value)}
							SelectProps={{
								MenuProps: {
									className: classes.menu,
								},
							}}
							margin="normal"
							variant="outlined"
						>
							{options.map((option,key) => (
								<MenuItem key={key} value={option.value}>
									{option.label}
								</MenuItem>
							))}
						</TextField>
					</FormControl>}
				{options.length===0 && <Typography>You have no Settlement Accounts!<br />Goto <Link onClick={props.openSettlementAccounts}>Add Settlement Accounts</Link><br /><br /></Typography>}
			</Grid>

			<Grid item xs={12}>
				{!person.isKYCValid && <Typography>Before you can withdraw funds, you must complete the KYC form. <br />Goto <Link onClick={props.openKYC}>Know Your Customer</Link> form<br /><br /></Typography>}
			</Grid>

			{person.isKYCValid && options.length>0 && <Button
				variant="contained"
				className={classes.button}
				component="span"
				onClick={()=>props.onClick({account, amount, settlementAccount})}
				disabled={amount < 1 || amount > props.amount}>
				Withdraw
			</Button>}

		</AppCard>
	);
}

const styles = theme => ({
	container: {
		display: 'flex',
		flexWrap: 'wrap',
	},
	textField: {
	},
	menu: {
		width: 200,
	},
	button: {
		...configTheme.lightButton,
	},
	paper: {
		boxShadow: '0px 20px 40px rgba(0,0,0,0.06)',
		padding: theme.spacing(2),
	}
});

export default withStyles(styles, { withTheme: true })(WithdrawUI); 