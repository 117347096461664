import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { inject, observer } from "mobx-react";
import { toJS } from 'mobx';
import HUE from '@material-ui/core/colors/grey';
import configTheme from '../../config/theme';
import ProfileEditUI from './ProfileEditUI';

const accountTileNameText = HUE[400];

class ProfileEditContainer extends React.Component {
	state = {
	}

	onChange = (event) => {
		const email = ('' + event.target.value).toLowerCase();
		// TODO use regex
		const isValid = email.indexOf('@') >= 1 && email.indexOf('.') >= 3 && 'qwertyuiopasdfghjklzxcvbnm1234567890+'.indexOf(email[email.length - 1]) >= 0;
		this.setState({ email, isValid });
	}

	onSubmit = async (values) => {
		await this.props.persons.update(values, { method: 'patch' });
	}

	async componentDidMount() {
		return Promise.all(['persons', 'countries'].map(a => this.props[a].check()));
	}

	handleUploadId = async () =>{
		console.log('called handleUploadId()');
		return null;
	}

	handleUploadBill = async () =>{
		console.log('called handleUploadBill()');
		return null;
	}

	render() {
		const { classes, persons, countries } = this.props;
		const { onSubmit, onChange, handleUploadId, handleUploadBill } = this;
		const initialValues = new persons.model(toJS(persons.person));
		return <ProfileEditUI {...{ classes, onSubmit, onChange, initialValues, countries, handleUploadId, handleUploadBill }} />;
	}
}

const styles = theme => ({
	menuButton: {
		marginLeft: 12,
		marginRight: 12,
	},
	paper: {
		padding: theme.spacing(2),
		textAlign: 'center',
		color: accountTileNameText,
	},
	button: {
		...configTheme.lightButton,
		marginTop: theme.spacing(2),
	},
	icon: {
		color: 'rgb(130,202,64)',
		fontSize: 100
	},
	textbox: {
		marginTop: theme.spacing(2),
	},
	text: {
		color: 'black',
		textAlign: 'center',
		padding: theme.spacing(2),
	}
});

export default withStyles(styles, { withTheme: true })(inject('persons', 'countries', 'routing')(observer(ProfileEditContainer)));
