import React from 'react';
import { Grid, Button } from '@material-ui/core'
import ChevronRight from '@material-ui/icons/ArrowForwardIos';

export default function Lines(options, navigateTo, classes, hashVariable) {
	return (
		<React.Fragment>
			<Grid item xs={12}>
				{options.map(option =>
					<Button className={classes.button1} key={option.id} onClick={() => navigateTo(hashVariable, option.slug)} disabled={!option.active}>
						<span>&nbsp;</span>
						{option.name}
						{option.active?<ChevronRight className={classes.icon} />:<span>&nbsp;</span>}
					</Button>
				)}
			</Grid>
		</React.Fragment>);
}
