import React from 'react';
import { Grid, Paper, Typography, Button } from '@material-ui/core'
import ChevronLeft from '@material-ui/icons/ArrowBackIos';
import IconButton from '@material-ui/core/IconButton';

export default function (props) {
	const { details, title, accountNumber, classes, onSendFundsClick } = props;
	const noAccountDetailsText = 'Sorry, account details for this currency are not yet available.<br /><br />Please check back soon.';
	return (
		<Grid container
			spacing={2}
			direction="row"
			justify="space-evenly"
			alignItems="center"
		>
			<Grid item xs={12} sm={3} md={3} lg={3}>
				<Paper className={classes.paper}
					style={{ boxShadow: '0px 20px 40px rgba(0,0,0,0.06)' }}>
					<Grid container>

						<Grid item xs={2} align="center">
							<IconButton onClick={() => props.onBack()}>
								<ChevronLeft />
							</IconButton>
						</Grid>

						<Grid item xs={8} align="center">
							<Typography color="textPrimary" style={{ fontFamily: 'Nunito', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }} variant="h5">
								{title}
							</Typography>
						</Grid>

						<Grid item xs={2} align="center">
							&nbsp;
						</Grid>

					</Grid>


					<Grid item xs={12}>
						<Typography dangerouslySetInnerHTML={{ __html: details || noAccountDetailsText }} />
						{!details ? '' :
							<Typography color="textSecondary" style={{ fontSize: 16, fontWeight: 500, color: 'darkgrey' }}>
								<span style={{ color: 'black' }}>Reference:</span>
								<br />
								{accountNumber}
							</Typography>
						}
						<br />
					</Grid>
					<Grid container >
						<Grid item xs={12}>
							{!details ? '' :
								<Button className={classes.button2} onClick={onSendFundsClick}>
									I have completed the transfer
								</Button>
							}
						</Grid>
					</Grid>
				</Paper>
			</Grid>

		</Grid >);
}