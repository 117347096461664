import React from 'react';
import { Grid, Paper } from '@material-ui/core';

export default function OptionSelectWithIcon(props) {
	const { classes, topBorderColour } = props;
	const paperStyle = { boxShadow: '0px 20px 40px rgba(0,0,0,0.06)' };
	if (topBorderColour) paperStyle.borderTop = `5px ${topBorderColour} solid`;

	return (
		<Grid container
			spacing={2}
			direction="row"
			justify="space-evenly"
			alignItems="center">
			<Grid item xs={12}>
				<Paper className={classes.paper} style={paperStyle}>
					<Grid container>
						{props.children}
					</Grid>
				</Paper>
			</Grid>
		</Grid>);
}