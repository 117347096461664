import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import configTheme from '../../config/theme';
import AppCard from '../OptionSelector/AppCard';
import Header from '../OptionSelector/Header';
import Lines from './Lines';
import { Typography, Grid } from '@material-ui/core';
import { Progress1 } from '../ProgressIndicators';

function AccountTransactionsUI(props) {
	const { classes, onBack, options=[], isBusy=false } = props;

	return (
		<AppCard classes={classes}>
			{Header(onBack, null, 'Transactions')}
			<Grid item xs={12}>
				<Typography variant="h6" align="center">Balance: {props.balance}</Typography>
			</Grid>
			{isBusy && <Progress1 text='Transactions' />}
			{Lines(options, undefined, classes)}
		</AppCard>
	);
}

const styles = theme => ({
	container: {
		display: 'flex',
		flexWrap: 'wrap',
	},
	textField: {
	},
	menu: {
		width: 200,
	},
	button: {
		...configTheme.lightButton,
	},
	paper: {
		boxShadow: '0px 20px 40px rgba(0,0,0,0.06)',
		padding: theme.spacing(2),
		textAlign: 'center'
	}
});


export default withStyles(styles, { withTheme: true })(AccountTransactionsUI);