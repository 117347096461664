import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import ProductList from './ProductList';
import ProductEdit from './ProductEdit';
import { inject, observer } from "mobx-react";
import HUE from '@material-ui/core/colors/grey';
import configTheme from '../../config/theme';

const accountTileNameText = HUE[400];

class ProductContainer extends React.Component {

	constructor(props) {
		super(props);
		this.props.products.list();
	}

	onBackToDashboard = () => {
		this.props.routing.push('dashboard');
	}

	onSubmitEdit = async (values) => {
		const result = await this.props.products.update(values);
		if (result === true) {
			window.history.back();
		}
	}

	onSubmitAdd = async (values) => {
		const result = await this.props.products.create(values);
		if (result === true) {
			window.history.back();
		}
	}

	getInitialRecord(hash) {
		let initialValues = {
			name: '',
			issuing_fi: '',
			tenor: 0,
			interest_rate: 0,
			product_type_id: '',
			account_id: '',
			minimum_amount: 0,
			description: '',
			terms_and_conditions: '',
			currency_id: '',
			active: false,
		};
		if (hash) {
			const id = parseInt(hash.substr(6));
			const item = this.props.products.data.find(item => item.id === id);
			if (item) {
				initialValues = Object.assign({}, item);
			}
		}
		return initialValues;
	}
	async componentDidMount() {
		return Promise.all(['products', 'financialInstitutions', 'currencies', 'accounts', 'productTypes'].map(a => this.props[a].check()));
	}

	render() {
		const { classes } = this.props;
		const accounts = this.props.accounts.data;
		const products = this.props.products.data;
		const fis = this.props.financialInstitutions.data;
		const productTypes = this.props.productTypes.data;
		const currencies = this.props.currencies.data;
		const stores = {accounts, products, fis, productTypes, currencies};

		const hash = window.location.hash;
		if (hash.length) {
			if (hash === '#add') {
				let initialValues = this.getInitialRecord(null);
				return <ProductEdit classes={classes} initialValues={initialValues} {...stores} onSubmit={this.onSubmitAdd}/>;
			}
			if (hash.indexOf('#edit') === 0) {
				let initialValues = this.getInitialRecord(hash);
				return <ProductEdit classes={classes} initialValues={initialValues} {...stores} onSubmit={this.onSubmitEdit} />;
			}
		};

		return <ProductList list={products} fis={fis} classes={classes} onBack={this.onBackToDashboard} />;
	}
}

const styles = theme => ({
	menuButton: {
		marginLeft: 12,
		marginRight: 12,
	},
	paper: {
		padding: theme.spacing(2),
		textAlign: 'left',
		color: accountTileNameText,
	},
	button1: {
		backgroundColor: 'black',
		color: 'white',
		width: '100%',
		margin: '4px 0px',
		boxShadow: `0px 3px 10px 0px grey`,
		fontFamily: 'Nunito',
		fontSize: 16,
		textTransform: 'initial',
	},
	button2: configTheme.lightButton,
	icon: {
		color: 'rgb(130,202,64)'
	}
});

export default withStyles(styles, { withTheme: true })(inject('routing', 'products', 'financialInstitutions', 'currencies', 'accounts', 'productTypes')(observer(ProductContainer)));