import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import ProductInvestorList from './ProductInvestorList';
import { inject, observer } from "mobx-react";
import { sendNotification, toCamelCase, urlToHashParams, hashParamsToURL, numberWithCommas, getBaseLocation, formatValue } from '../../libs/common';
import { OptionSelector } from '../OptionSelector';
import ProductInvestorNew from './ProductInvestorNew';
import ProductInvestSummary from './ProductInvestSummary';

import HUE from '@material-ui/core/colors/grey';
import configTheme from '../../config/theme';

const accountTileNameText = HUE[400];


class ProductInvestorContainer extends React.Component {
	state = {
		localHistory: [getBaseLocation()],
		baseURL: getBaseLocation(),
		basePath: 'invest',
	}

	navigateTo = (key, value) => {
		const hashKey = toCamelCase(key);
		const hashParams = urlToHashParams(window.location.hash);

		hashParams[hashKey] = value;

		const currentState = hashParamsToURL(this.state.baseURL, hashParams);

		window.history.replaceState(null, null, currentState);
		this.setState({ localHistory: [...this.state.localHistory, currentState] },
		);
	}

	navigateBack = () => {
		if (this.state.localHistory.length) {
			let value = this.state.localHistory.pop();

			if (value === window.location.href) {
				return this.navigateBack();
			}

			window.history.replaceState(null, null, value);
			this.setState({ localHistory: [...this.state.localHistory, value] },
			);
		} else {
		}
	}

	resetState = () => {
		this.setState({ localHistory: [getBaseLocation()] });
		return null;
	}

	async componentDidMount() {
		return Promise.all(['products', 'currencies', 'financialInstitutions', 'countries', 'productTypes', 'portfolio', 'accounts'].map(a => this.props[a].check()));
	}

	onSubmitAdd = async (values) => {
		const result = await this.props.portfolio.create(values);
		delete localStorage['temp_amount'];
		if (result === true) {
			sendNotification('Investment Submitted!');
			this.props.routing.replace('portfolio#?portfolio-id=latest');
		}
	}
	onAddFunds = async () => {
		return this.props.routing.replace('account-actions#?operation=add-funds');
	}

	render() {
		const { classes, products, financialInstitutions, productTypes, currencies, session, accounts } = this.props;
		const hashParams = urlToHashParams(window.location.hash);
		const productTypesMap = productTypes.data.reduce((a, c) => Object.assign(a, { [c.slug]: c }), {});

		// test for the special case where the user uses some other navigation path to skip right
		// back to the baseUrl, leaving localHistory with ancient history
		if (Object.keys(hashParams).length === 0 && this.state.localHistory.length > 1) {
			return <this.resetState />;
		}

		if (!hashParams.productType) {
			const title = 'Product Type';
			const subTitle = <span>&nbsp;</span>;

			return <OptionSelector onSubmit={this.onSubmitAdd} classes={classes} options={productTypes.data} title={title} subTitle={subTitle} navigateTo={this.navigateTo} hashVariable="product-type" />;
		}

		if (!hashParams.productId) {

			const fisLogos = financialInstitutions.data.reduce((a, c) => Object.assign(a, { [c.id]: c.url_logo }), {});
			let availableProducts = products.data;

			let productTypeId = productTypesMap[hashParams.productType] ? productTypesMap[hashParams.productType].id : null;
			if (!productTypeId) {
				console.log('productTypeId is undefined, using All');
			} else {
				availableProducts = products.data.filter(a => a.product_type_id === productTypeId);
			}

			const options = availableProducts.map(a => {
				return ({
					id: a.id,
					name: a.name,
					enabled: true,
					slug: a.slug,
					type: a.product_type_id,
					interest_rate: a.interest_rate,
					tenor: a.tenor,
					issuing_fi_url_logo: fisLogos[a.issuing_fi],
				})
			});
			const title = 'Products';
			const subTitle = 'Select a product';
			return (
				<ProductInvestorList
					title={title}
					subTitle={subTitle}
					list={options}
					filterId={hashParams.productType}
					productTypes={productTypes.data}
					classes={classes}
					onBack={this.navigateBack}
					navigateTo={this.navigateTo}
					hashVariableList="product-id"
					hashVariableFilter="product-type" />
			);
		}

		if (!hashParams.amount) {
			let product = products.data.find(a => a.id === parseInt(hashParams.productId, 10));
			if (product === undefined) {
				console.error(`hashParams.productId=${hashParams.productId} produced no result`);
				return null;
			}
			product = Object.assign({}, product);
			product.minimum_amount = formatValue(product.minimum_amount);

			const fisLogos = financialInstitutions.data.reduce((a, c) => Object.assign(a, { [c.id]: c.url_logo }), {});
			const fis = Object.assign(
				{},
				financialInstitutions.data.find(a => a.id === parseInt(product.issuing_fi, 10)),
				{ issuing_fi_url_logo: fisLogos[product.issuing_fi] },
			);

			let initialValues = {
				terms_and_conditions: false,
				product_id: product.id,
				amount: product.minimum_amount,
				minimum_amount: product.minimum_amount,
			};
			const currency = currencies.findByField('id', product.currency_id);

			let tempAmount = parseInt(localStorage['temp_amount']); // hack to bring amount back to Buy Investment UI from summary.  should probably refactor to use a store
			if (!isNaN(tempAmount) && tempAmount > 0) {
				initialValues.amount = tempAmount;
				delete localStorage['temp_amount'];
			}

			return (
				<ProductInvestorNew
					classes={classes}
					navigateTo={this.navigateTo}
					onBack={this.navigateBack}
					title='Buy Investment'
					fis={fis}
					product={product}
					currency={currency}
					initialValues={initialValues}
					hashVariableList="amount"
					hashVariableFilter="amount"
				/>);
		}

		// not sure if this should even be happening.
		if (products.data.length < 1) {
			console.log('no products');
			return '';
		}
		if (accounts.data.length < 1) {
			console.log('no accounts');
			return '';
		}
		if (!session.person) {
			console.log('no person');
			return '';
		}

		const product = products.data.find(a => a.id === parseInt(hashParams.productId, 10));
		const currency = currencies.findByField('id', product.currency_id);
		let account = accounts.findUsersCurrencyAccount(session.person.id, product.currency_id);
		if (!account) return `You do not have an account using this product's currency`;
		account = Object.assign({}, account);
		account.cash_balance = formatValue(account.cash_balance);

		const amount = parseInt(hashParams.amount);
		localStorage['temp_amount'] = amount; // hack to bring amount back to Buy Investment UI. should probably refactor to use a store
		const yearlyEarnings = parseInt((amount / 100) * product.interest_rate);
		const earningsOnProduct = parseInt((yearlyEarnings / 365) * product.tenor);
		const taxOnEarnings = parseInt((!product.taxOnInterest) ? 0 : ((earningsOnProduct / 100) * product.taxOnInterest));
		const data = {
			amount: numberWithCommas(amount),
			symbol: currency.symbol,
			tenor: product.tenor,
			interest_rate: product.interest_rate,
			earnings: numberWithCommas(earningsOnProduct),
			taxRateOnEarnings: product.taxOnInterest,
			taxOnEarnings,
			cashBalanceBefore: numberWithCommas(account.cash_balance),
			cashBalanceAfter: numberWithCommas(account.cash_balance - amount),
			notEnoughCash: (account.cash_balance - amount) < 0,
			product,
			amountRaw: amount * 100,
		};

		return <ProductInvestSummary classes={classes} data={data} title='Summary' onBack={this.navigateBack} onSubmit={this.onSubmitAdd} onAddFunds={this.onAddFunds} />;
	}
}

const styles = theme => ({
	menuButton: {
		marginLeft: 12,
		marginRight: 12,
	},
	paper: {
		padding: theme.spacing(2),
		textAlign: 'center',
		color: accountTileNameText,
		// width: 400,
	},
	button1: configTheme.lightTrypticButton,
	button2: configTheme.lightButton,
	icon: {
		color: 'rgb(130,202,64)'
	},
});

export default withStyles(styles, { withTheme: true })(inject('routing', 'products', 'currencies', 'financialInstitutions', 'countries', 'productTypes', 'portfolio', 'accounts', 'session')(observer(ProductInvestorContainer)));