import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { inject, observer } from "mobx-react";
import HUE from '@material-ui/core/colors/grey';
import { OptionSelector } from '../OptionSelector';
import { SettlementAccountEdit } from '.';
import configTheme from '../../config/theme';
import Add from '@material-ui/icons/Add';
const accountTileNameText = HUE[400];

class SettlementAccountContainer extends React.Component {

	onBackToDashboard = () => {
		this.props.routing.push('dashboard');
	}

	navigateTo = (key, value) => {
		console.log({key, value})
		this.props.routing.push(`#${key}/${value}`);
	}

	onSubmitEdit = async (values) => {
		const result = await this.props.settlementAccounts.update(values);
		if (result === true) {
			window.history.back();
		}
	}

	onSubmitAdd = async (values) => {
		const result = await this.props.settlementAccounts.create(values);
		if (result === true) {
			window.history.back();
		}
	}

	componentDidMount(){
		this.props.settlementAccounts.check();
	}

	render() {
		const { classes, settlementAccounts } = this.props;

		const hash = window.location.hash;
		if (hash.length){
			if (hash==='#add'){
				let initialValues = settlementAccounts.getInitialValues();
				return <SettlementAccountEdit classes={classes} initialValues={initialValues} onSubmit={this.onSubmitAdd} />
			}
			if (hash.indexOf('#edit') === 0) {
				const id = parseInt(hash.substr(6));
				let initialValues = settlementAccounts.getInitialValues(id);
				return <SettlementAccountEdit classes={classes} initialValues={initialValues} onSubmit={this.onSubmitEdit} title='Edit Account' />;
			}
		}

		const options = (!settlementAccounts.data?[]:settlementAccounts.data).map(a=>({
			id: a.id,
			name: a.name,
			slug: a.id,
			active: true,
		}))

		const title = `Edit/Add`;
		return (
			<OptionSelector
				classes={classes}
				options={options}
				title={title}
				navigateTo={this.navigateTo}
				buttonSize={'small'}
				onPlus={()=>this.props.routing.push('#add')}
				rightIcon={Add}
				hashVariable="edit"
			/>
		);
	}
}

const styles = theme => ({
	menuButton: {
		marginLeft: 12,
		marginRight: 12,
	},
	paper: {
		padding: theme.spacing(2),
		textAlign: 'center',
		color: accountTileNameText,
	},
	button1: configTheme.lightTrypticButton,
	button2: configTheme.lightButton,
	icon: {
		color: 'rgb(130,202,64)'
	},
});


export default withStyles(styles, { withTheme: true })(inject('routing', 'settlementAccounts')(observer(SettlementAccountContainer)));
