import React from 'react';
import { inject, observer } from "mobx-react";
import ExchangeUI from './ExchangeUI';
import { sendNotification } from '../../libs/common';

class ExchangeContainer extends React.Component {
	state = {
		exchangeCurrencies: [{ id: 76, value: 0, label: 'NGN' }, { id: 19, value: 1, label: 'GBP' }],
		currencyFrom: 0,
		currencyTo: 0,
		amountFrom: '',
		amountTo: '',
	};

	componentDidMount = async () => {
		const currencyIdFrom = this.props.account.currency_id;
		const currencyIdTo = this.props.otherAccount.currency_id;
		this.setState({currencyFrom: currencyIdFrom, currencyTo: currencyIdTo});
		const exchangeRate = await this.props.currencies.getRate({ currencyIdFrom, currencyIdTo });
		exchangeRate.rateId = exchangeRate.id;
		if (exchangeRate) {
			this.setState(exchangeRate);
		}
	}

	amountFromSet = (amountFrom) => {
		let rate = this.state.rate;
		this.setState({
			amountFrom,
			amountTo: (amountFrom * rate).toFixed(2),
		});
	}

	amountToSet = (amountTo) => {
		let rate = this.state.rate;
		this.setState({
			amountTo,
			amountFrom: ( amountTo / rate || 0).toFixed(2)
		});
	}

	onSubmit = async () => {
		const config = {
			accountFromId: this.props.account.id,
			accountToId: this.props.otherAccount.id,
			currencyIdFrom: this.state.currencyFrom,
			currencyIdTo: this.state.currencyTo,
			amountFrom: this.state.amountFrom * 100,
			amountTo: this.state.amountTo * 100,
			rateId: this.state.rateId,
		};
		try {
			await this.props.accounts.doExchange(config);
		} catch(error){
			sendNotification(error.message,'warning');
		}
	}

	render() {
		return <ExchangeUI {...this.state} {...this.props} amountToSet={this.amountToSet} amountFromSet={this.amountFromSet} onSubmit={this.onSubmit} />;
	}
}

export default inject('currencies', 'accounts')(observer(ExchangeContainer));