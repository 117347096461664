import React from 'react';
import Titles from './Titles';
import Header from './Header';
import Lines from './Lines';
import AppCard from './AppCard';
import Content from './Content';

export default function OptionSelectWithIcon(props) {
	const { classes, subTitle, subTitleText, hashVariable, buttonSize = 'default', onPlus=null, onBack, navigateTo, leftIcon, rightIcon, title, html} = props;
	let options = props.options;
	if ((!options || !options.length) && !html){
		options = [{id: 1,
			name: 'No data available',
			slug: '',
			active: false,}]
	}
	return (
		<AppCard classes={classes}>
			{Header(onBack, onPlus, title, buttonSize, leftIcon, rightIcon)}
			{(subTitle || subTitleText) && Titles(subTitle, subTitleText)}
			{options && Lines(options, navigateTo, classes, hashVariable)}
			{html && Content(html)}
		</AppCard>
	);
}