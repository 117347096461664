import React from 'react';
import { Grid, Typography, Table, TableBody, TableRow, TableCell } from '@material-ui/core';
import { numberWithCommas } from '../../libs/common';

export default function Lines(options, navigateTo, classes, hashVariable) {
	return (
		<React.Fragment>
			<Grid item xs={12}>
				<Table padding="none">
					{options.length === 0 ? (
						<TableBody>
							<TableRow>
								<TableCell style={{ textAlign: 'center', width: 48 }}>&nbsp;</TableCell>
							</TableRow>
							<TableRow>
								<TableCell style={{ textAlign: 'center', width: 48 }}>
									<Typography variant="body1">Nothing to show</Typography>
								</TableCell>
							</TableRow>
						</TableBody>)
						: null}
					{options.map((item, idx) =>
						(<TableBody key={`${hashVariable}-${idx}`}>
							<TableRow hover>
								<TableCell style={{ height: 60, textAlign: 'left', width:'20%' }}>{new Date(item.date_created).toString().substr(4,6)}</TableCell>
								<TableCell style={{ height: 60, textAlign: 'left' }}>{item.description}</TableCell>
								<TableCell style={{ height: 60, textAlign: 'right', width:'30%' , color: item.amount<0?'red':'green' }}>{numberWithCommas(item.amount)}</TableCell>
							</TableRow>
						</TableBody>))}
				</Table>
			</Grid>
		</React.Fragment>);
}
