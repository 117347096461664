import React from 'react';
import { Grid, Paper, Table, TableBody, TableCell, TableRow } from '@material-ui/core'
import ChevronRight from '@material-ui/icons/ArrowForwardIos';
import IconButton from '@material-ui/core/IconButton';
import { Formik } from 'formik';
import TextField from '@material-ui/core/TextField';

export default function (props) {
	const { classes, list, fis } = props;
	const filterList = (list, name) => {
		if (!name) return list;
		return list.filter(item => item.name.toLowerCase().indexOf(name.toLowerCase()) >= 0);
	}
	const getFILogoURL = (fi_id)=>(fis.filter(fi=>fi.id===fi_id)[0]||{}).url_logo;
	
	return (
		<Grid container
			spacing={2}
			direction="row"
			justify="space-evenly"
			alignItems="center"
		>
			<Grid item xs={12}>
				<Paper className={classes.paper}
					style={{ boxShadow: '0px 20px 40px rgba(0,0,0,0.06)' }}>
					<Grid container spacing={0}>
						<Grid item xs={12}>
							<Formik
								enableReinitialize
								initialValues={{
									id: '',
									financialInstitutionName: '',
								}}
								onSubmit={(values, actions) => {
									this.handleSubmit(values);
								}}
								render={props => {
									return (
										<React.Fragment>
											<TextField
												className={classes.textFieldContainer}
												InputProps={{ classes: { input: classes.textField } }}
												onChange={props.handleChange}
												onBlur={props.handleBlur}
												value={props.values.financialInstitutionName}
												name="financialInstitutionName"
												label="Search"
												margin="normal"
												type="text"
												fullWidth
											/>

											<Table width="100%" style={{ color: 'black', fontSize:'14px' }} padding="none">
												{list && (!props.values.financialInstitutionName ? list : filterList(list, props.values.financialInstitutionName)).map((item, idx) => {
													return (
														<TableBody key={`product-${idx}`}>
															<TableRow  href={`#edit/${item.id}`}>
																<TableCell><img style={{ height: 32, width: 32 }} alt="" src={getFILogoURL(item.issuing_fi)} /></TableCell>
																<TableCell style={{ textAlign: 'left' }}>{item.name}</TableCell>
																<TableCell style={{ textAlign: 'right' }}>{item.interest_rate}%</TableCell>
																<TableCell style={{ textAlign: 'right' }}>{item.tenor} days</TableCell>
																<TableCell style={{ textAlign: 'right' }}>
																	<IconButton href={`#edit/${item.id}`}>
																		<ChevronRight className={classes.icon} />
																	</IconButton>
																</TableCell>
															</TableRow>
														</TableBody>
													);
												})}
											</Table>

										</React.Fragment>
									);
								}}
							/>
						</Grid>
						<Grid item xs={12}>
						</Grid>
					</Grid>
				</Paper>
			</Grid>
		</Grid>);
}