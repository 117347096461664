import { decorate, runInAction } from 'mobx';
import GenericStore from './GenericStore';

class TransactionStore extends GenericStore {
	apiName = 'transaction';
	singular = 'transaction';
	plural = 'transactions';

	//@action.bind
	check = async (accountId) => {
		let path = `/account/${accountId}/transactions`;
		let headers = !this.requiresAuthentication ? {} : await this.getInitialisedMessage();
		runInAction (()=>{
			this.isBusy = true;
		});

		return this.API.get(this.apiName, path, headers).then(response => {
			runInAction(() => {
				this.setData(response.data[this.plural]);
				this.isBusy = false;
			});
			return true;
		}).catch(error => {
			runInAction(() => {
				this.isBusy = false;
				this.processErrorCode(error);
			});
			return false;
		});
	}

}

decorate(TransactionStore, {
});

export default new TransactionStore();
