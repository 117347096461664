import React from 'react';

const EcoIcon = (props)=>(
<svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="29px"
	 height="29px" viewBox="0 0 24 24" enableBackground="new 0 0 24 24" fill={props.color}>
		<path d="M6.05,8.05c-2.73,2.73-2.73,7.15-0.02,9.88c1.47-3.4,4.09-6.24,7.36-7.93c-2.77,2.34-4.71,5.61-5.39,9.32
			c2.6,1.23,5.8,0.78,7.95-1.37C19.43,14.47,20,4,20,4S9.53,4.57,6.05,8.05z"/>
</svg>
	);
	
export default EcoIcon;