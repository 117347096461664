import * as React from "react";
import { Switch, Redirect } from "react-router-dom";
import { Grid, Paper, Typography } from '@material-ui/core';
import { inject, observer } from 'mobx-react';
import { default as AppliedRoute } from './AppliedRoutes';
import { withRouter } from "react-router";
import { DashboardHeaderPartnerSummary } from "../DashboardHeaderPartnerSummary";
import Add from '@material-ui/icons/PersonAdd';
import IconButton from '@material-ui/core/IconButton';

class Routes extends React.Component {
  onBack = () => {
    window.history.back();
  }
  render() {
    const { sitemap, routing } = this.props;
    const childProps = {};

    let pages = sitemap.pages.map((page, index) => {
      return <AppliedRoute exact path={page.path} component={page.component} props={childProps} key={index} />;
    });

    // Add a default route
    let defaultPage = 0;
    sitemap.pages.some((a, idx) => a.default ? defaultPage = idx : false);
    let redirect = <Redirect to={sitemap.pages[defaultPage].path} key={sitemap.pages[defaultPage].path} />;
    pages.push(redirect);

    const pathmap = sitemap.pages.reduce((a, c) => Object.assign(a, { ['' + c.path]: c }), {})
    const page = pathmap[routing.location.pathname];

    let header;
    if (page && !page.hideHeader) {
      if (!page.headerComponent) {
        header = (
          <Paper style={{ fontFamily: 'Nunito', maxHeight: 90, padding: '16px 0px 16px 0', boxShadow: '0px 2px 4px rgba(0,0,0,0.04)' }}>
            <Grid container>

              <Grid item xs={1} align="center">
                {/* <IconButton onClick={() => this.onBack()}>
                  <ChevronLeft />
                </IconButton> */}
              </Grid>

              <Grid item xs={8}>
                <Typography style={{ fontFamily: 'Nunito', height: '100%', display: 'flex', alignItems: 'center' }} variant="h5">
                  {page.text}
                </Typography>
              </Grid>

              <Grid item xs={2} align="center">
                {!page.optionButton ? '' : (
                  <IconButton href={`#${page.optionButton}`}>
                    <Add />
                  </IconButton>)}
              </Grid>

            </Grid>
            <Typography variant="subtitle1" style={{ fontFamily: 'Nunito', fontSize: 16, fontWeight: 500, color: 'darkgrey' }}>{page.subtext}</Typography>
          </Paper>);
      } else {
        header = <DashboardHeaderPartnerSummary />;
      }
    }
    return (
      <div>
        {header}
        <div style={{ padding: 16, }}>
          <Switch>{pages}</Switch>
        </div>

      </div>
    );
  }
}

export default withRouter(inject('routing', 'session')(observer(Routes)));