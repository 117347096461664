import { decorate } from 'mobx';
import GenericStore from './GenericStore';

class PortfolioStore extends GenericStore {
	apiName = 'portfolio';
	singular = 'portfolio';
	plural = 'portfolio';

	// constructor() {
	// 	super();
	// 	this.init();
	// }
}

decorate(PortfolioStore, {
});

export default new PortfolioStore();
