import React from 'react';
import { Grid, Paper, Typography, Button, IconButton } from '@material-ui/core'
import ChevronRight from '@material-ui/icons/ArrowForwardIos';
import ChevronLeft from '@material-ui/icons/ArrowBackIos';

let id = 0;
function nextId() {
	return ++id;
}

function portfolioPanel(account, classes) {
	return (
		<Grid key={nextId()} item xs={12} sm={6} md={6} lg={6}>
			<Paper className={classes.paper}
				style={{ boxShadow: '0px 20px 40px rgba(0,0,0,0.06)' }}>
				<Grid container spacing={1}>

					<Grid item xs={12} align="center" onClick={account.onTitleClick}>
						<Typography variant="h5" color="textPrimary" style={{ fontFamily: 'Nunito', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
							{account.currencySymbol} {account.value}
						</Typography>
					</Grid>

					<Grid item xs={12} onClick={account.onTitleClick}>
						<Typography color="textSecondary" style={{ fontSize: 16, fontWeight: 500, color: 'darkgrey' }}>
							{account.name}
						</Typography>
					</Grid>
					<Grid item xs={12}>
						<Button className={account.buttonClass} onClick={account.onClick}>
							{account.buttonText}
						</Button>
					</Grid>
				</Grid>
			</Paper>
		</Grid>
	);
}


function accountPanel(account, classes, onNextAccount) {
	return (
		<Grid key={nextId()} item xs={12} sm={6} md={6} lg={6}>
			<Paper className={classes.paper}
				style={{ boxShadow: '0px 20px 40px rgba(0,0,0,0.06)' }}>
				<Grid container spacing={1}>

					<Grid item xs={2} align="center">
						<IconButton onClick={onNextAccount}>
							<ChevronLeft fontSize="small" />
						</IconButton>
					</Grid>

					<Grid item xs={8} align="center" onClick={account.onTitleClick}>
						<Typography variant="h5" color="textPrimary" style={{ fontFamily: 'Nunito', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
							{account.currencySymbol} {account.value}
						</Typography>
					</Grid>

					<Grid item xs={2} align="center">
						<IconButton onClick={onNextAccount} >
							<ChevronRight fontSize="small" />
						</IconButton>
					</Grid>

					<Grid item xs={12} onClick={account.onTitleClick}>
						<Typography color="textSecondary" style={{ fontSize: 16, fontWeight: 500, color: 'darkgrey' }}>
							{account.name}
						</Typography>
					</Grid>
					<Grid item xs={12}>
						<Button className={account.buttonClass} onClick={account.onClick}>
							{account.buttonText}
						</Button>
					</Grid>
				</Grid>
			</Paper>
		</Grid>
	);
}

export default function (props) {

	const { accountsData, classes, onNextAccount, selectedAccount = 0 } = props;
	const portfolio = accountsData[0];
	const accounts = [accountsData[1], accountsData[2]];

	return (
		<Grid container key={nextId()}
			spacing={3}
			direction="row"
			justify="space-evenly"
			alignItems="center"
		>
			{portfolioPanel(portfolio, classes)}
			{accountPanel(accounts[selectedAccount], classes, onNextAccount)}
		</Grid>);
}