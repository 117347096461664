import React from "react";
import { observer, inject } from "mobx-react";
import { FormControl, Input, Button } from "@material-ui/core";

const FileUploader = observer(props => {
	const store = props.persons;
	const { classes } = props;

	const submitFile = async (file) => {
		try {
			await store.uploadDocument('test', file);
		} catch (err) {
			console.error("submit file error", err);
			store.error = true;
			store.errorMessage = "Something went wrong while file upload.";
		}
	};

	const handleChange = event => {
		event.persist();
		event.preventDefault();
		if (event.target.files.length) submitFile(event.target.files[0]);
	};

	const id = '' + new Date().valueOf();
	return (
		<FormControl fullWidth>
			<Input type="file" onChange={handleChange} id={id} style={{ display: 'none' }} />
			<label htmlFor={id}>
				<Button className={classes.button2} variant="contained" component="span">
					{props.text}
				</Button>
			</label>
		</FormControl>
	);
});

export default inject("persons")(FileUploader);
