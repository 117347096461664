const colours = {
	primaryCTAColour: 'rgb(130,202,62)',
	primaryCTATextColour: 'white',
	primaryGrey: 'rgb(192,197,205)',

	primaryBlue: 'rgb(21,159,236)',

	investorColour: 'rgb(130,202,62)',
	investorColourFaded: 'rgba(110,109,95,0.6)',
	institutionColour: 'rgb(21,159,236)',
	institutionColourFaded: 'rgb(21,159,236,0.4)',
	carouselPipsColour: 'rgb(220,220,220)',
};
const configTheme = {

	...colours,

	darkButton: {
		backgroundColor: 'black',
		color: 'white',
		width: '100%',
		boxShadow: `0px 3px 10px 0px grey`,
		fontFamily: 'Nunito',
		textTransform: 'initial',
		fontSize: 16,
		'&:hover': {
			color: "#777",
		}
	},

	lightButton: {
		backgroundColor: colours.primaryCTAColour,
		color: 'white',
		width: '100%',
		boxShadow: `0px 3px 10px 0px ${colours.investorColourFaded}`,
		fontFamily: 'Nunito',
		textTransform: 'initial',
		fontSize: 16,
		'&:hover': {
			color: "#777",
			transition: 'color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms'
		},
	},

	lightTrypticButton: {
		color: 'black',
		width: '100%',
		margin: '16px 0px',
		fontFamily: 'Nunito',
		fontSize: 16,
		border: '1px solid #eee',
		borderRadius: '0px',
		display: 'flex',
		justifyContent: 'space-between',
		textTransform: 'capitalize',
	},

	portfolioStatusColours: {
		'SUBMITTED': 'orange',
		'PROCESSING': 'orange',
		'BOOKED': '#82CA40',
		'MATURED': 'red',
		'LIQUIDATED': 'black',
	},
};

export default configTheme;