import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import { Grid, Typography, Button, Divider, FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';
import cssTheme from '../../config/theme';
import { Formik } from 'formik';
import TextField from '@material-ui/core/TextField';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import { inject, observer } from "mobx-react";
import { withRouter } from "react-router";
import { BusyIndicator } from '../BusyIndicator';
import { sendNotification } from '../../libs/common';

const styles = theme => ({
	root: {
		flexGrow: 1,
		backgroundPositionX: '100%',
		backgroundRepeat: 'no-repeat',
		height: '700px',
		fontFamily: 'Nunito',
	},
	paper: {
		padding: 0,
		color: theme.palette.text.secondary,
		background: 'none',
	},
	button: {
		width: '130px',
		background: 'white',
		color: cssTheme.primaryCTAColour,
		'&:hover': {
			color: 'black',
			backgroundColor: cssTheme.primaryCTAColour
		}
	},
	textField: {
		fontSize: '1.5rem',
		fontFamily: 'Nunito'
	},
	textFieldContainer: {
		fontSize: '1.5rem',
		boxShadow: '0px 15px 30px rgba(0,0,0,0.08)',
		borderRadius: 3,
		// border: '1px solid rgb(232,233,232)',
	},
	registrationFormMargin: {
		marginLeft: theme.spacing(1),
		marginRight: theme.spacing(1),
	},
	moreLink: {

	},
	toggleContainer: {
		height: 56,
		margin: `${theme.spacing()}px 0`,
		background: theme.palette.background.default,
		display: 'inline-flex'
	},
	basicToggleStyle: {
		textTransform: 'none',
		fontFamily: 'Nunito',
		backgroundColor: '#ddd !important',
		color: 'white !important',
	},
	selectedToggle: {
		backgroundColor: cssTheme.primaryCTAColour + ' !important',
	},
	buttonStyle: {
		textTransform: 'none',
		fontFamily: 'Nunito',
		backgroundColor: cssTheme.primaryCTAColour + ' !important',
		color: 'white !important',
		borderRadius: '3px',
		boxShadow: '0px 1px 5px 0px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 3px 1px -2px rgba(0,0,0,0.12)'
	},
});

const SIGNIN = 'sign-in', SIGNUP = 'sign-up';


class RegistrationForm extends React.Component {
	constructor() {
		super();
		this.state = {
			errors: ''
		};
	}
	handleSubmit = async (values) => {

		if (values.signinOrSignup !== SIGNUP) {
			let error = await this.props.session.doLogin(values);
			await this.props.session.updateCurrentUserInfo();
			if (error !== false) {
				return this.displayLoginError(error);
			}
			this.props.routing.push('/dashboard');
			return;
		}

		let error = await this.props.session.doRegister(values);
		if (error !== false) return this.displayLoginError(error);

		this.props.notifications.addToMessageQueue({message: 'We have sent an email to your address.\nPlease access your email to verify and complete the sign up process.', type:'success'});	

		this.props.routing.push(`/login#${SIGNIN}`);
	}

	DISPLAY_USER_ERROR_DURATION = 10000;
	displayLoginError(errors) {
		sendNotification(errors, 'warning');
		// this.setState({ errors });
		// window.setTimeout(() => {
		// 	this.setState({ errors: '' });
		// }, this.DISPLAY_USER_ERROR_DURATION)
	}

	componentDidMount = () => {
		this.props.countries.check();
	}

	render() {
		const { classes, session, countries, routing, /* unit, ...data */ } = this.props;
		const isAuthenticating = session.isAuthenticating;
		let initialFormToDisplay = routing.location.hash || `#${SIGNIN}`;
		if (initialFormToDisplay[0] === '#') initialFormToDisplay = initialFormToDisplay.substr(1);

		const countriesOfOrigin = countries.data.filter(c => c.country_of_origin);
		const countriesOfResidence = countries.data.filter(c => c.country_of_residence);
		return (
			<Grid container justify="center">
				<Grid item xs={12} sm={8} md={6} lg={4} style={{ boxShadow: '0px 20px 40px rgba(0,0,0,0.06)', }}>
					<Formik
						enableReinitialize
						initialValues={{
							firstName: '',
							lastName: '',
							email: '',
							password: '',
							showPassword: false,
							signinOrSignup: initialFormToDisplay,
							country_of_origin_id: '',
							country_of_residence_id: '',
						}}
						onSubmit={(values, actions) => {
							this.handleSubmit(values);
						}}
						render={props => {
							const signinOrSignup = props.values.signinOrSignup;
							return (
								<React.Fragment>

									<Paper style={{ padding: 16 }} elevation={0}>

										<Grid container>
											<Grid item xs={12} style={{ display: 'flex', justifyContent: "space-between", alignItems: "center" }}>
												<Typography variant="h6" style={{ fontFamily: 'Nunito', display: 'inline-flex' }}>
													{signinOrSignup === SIGNUP ? 'Sign Up' : 'Sign In'}
												</Typography>
												<div className={classes.toggleContainer}>
													<ToggleButtonGroup
														name="signinOrSignup"
														value={signinOrSignup}
														onChange={(e) => window.location.hash = `#${e.currentTarget.value}`}
														onBlur={props.handleBlur}
														exclusive
														style={{ height: '100%', fontFamily: 'Nunito', }}>
														<ToggleButton classes={{ root: classes.basicToggleStyle, selected: classes.selectedToggle }} name="signinOrSignup" value={SIGNIN} style={{ height: '100%' }}>
															Sign In
														</ToggleButton>
														<ToggleButton classes={{ root: classes.basicToggleStyle, selected: classes.selectedToggle }} name="signinOrSignup" value={SIGNUP} style={{ height: '100%' }}>
															Sign Up
														</ToggleButton>
													</ToggleButtonGroup>
												</div>
											</Grid>
										</Grid>
									</Paper>

									<Divider />

									<Paper style={{ padding: '16px 32px 32px 32px' }} elevation={0}>
										<form onSubmit={props.handleSubmit}>
											{signinOrSignup === SIGNIN && (
												<React.Fragment>
													<TextField
														autoFocus
														className={classes.textFieldContainer}
														InputProps={{ classes: { input: classes.textField } }}
														onChange={props.handleChange}
														onBlur={props.handleBlur}
														value={props.values.email}
														name="email"
														label="Email "
														margin="normal"
														required
														type="email"
														fullWidth
														autoComplete="email"
													/>
													<TextField
														className={classes.textFieldContainer}
														InputProps={{ classes: { input: classes.textField } }}
														onChange={props.handleChange}
														onBlur={props.handleBlur}
														value={props.values.password}
														name="password"
														label="Password"
														margin="normal"
														required
														type="password"
														fullWidth
														autoComplete="current-password"
													/>
												</React.Fragment>
											)}
											{signinOrSignup !== SIGNIN && (
												<React.Fragment>

													<TextField
														autoFocus
														className={classes.textFieldContainer}
														InputProps={{ classes: { input: classes.textField } }}
														onChange={props.handleChange}
														onBlur={props.handleBlur}
														value={props.values.firstName}
														name="firstName"
														label="First Name"
														margin="normal"
														required
														type="text"
														fullWidth
														autoComplete="firstname"
													/>
													<TextField
														className={classes.textFieldContainer}
														InputProps={{ classes: { input: classes.textField } }}
														onChange={props.handleChange}
														onBlur={props.handleBlur}
														value={props.values.lastName}
														name="lastName"
														label="Last Name"
														margin="normal"
														required
														type="text"
														fullWidth
														autoComplete="last-name"
													/>
													<TextField
														className={classes.textFieldContainer}
														InputProps={{ classes: { input: classes.textField } }}
														onChange={props.handleChange}
														onBlur={props.handleBlur}
														value={props.values.email}
														name="email"
														label="Email "
														margin="normal"
														required
														type="email"
														fullWidth
														autoComplete="email"
													/>
													<TextField
														className={classes.textFieldContainer}
														InputProps={{ classes: { input: classes.textField } }}
														onChange={props.handleChange}
														onBlur={props.handleBlur}
														value={props.values.password}
														name="password"
														label="Password"
														margin="normal"
														required
														type="password"
														fullWidth
														autoComplete="new-password"
													/>
													<FormControl
														required
														margin="normal"
														fullWidth
													>
														<InputLabel
															required={true} htmlFor="country_of_origin_id">Country of Origin</InputLabel>
														<Select
															className={classes.textFieldContainer}
															value={props.values.country_of_origin_id}
															onChange={props.handleChange}
															onBlur={props.handleBlur}
															name="country_of_origin_id"
															label="Country of Origin"
															required
															inputProps={{
																name: 'country_of_origin_id',
																id: 'country_of_origin_id',
																required: true,
															}}
														>
															<MenuItem value=""><em>None</em></MenuItem>
															{countriesOfOrigin.map(c => <MenuItem key={`country-${c.id}`} value={c.id}>{c.name}</MenuItem>)}
														</Select>
													</FormControl>

													<FormControl
														required
														margin="normal"
														fullWidth
													>
														<InputLabel required={true} htmlFor="country_of_residence_id">Country of Residence</InputLabel>
														<Select
															className={classes.textFieldContainer}
															value={props.values.country_of_residence_id}
															onChange={props.handleChange}
															onBlur={props.handleBlur}
															name="country_of_residence_id"
															label="Country of Residence"
															required
															inputProps={{
																name: 'country_of_residence_id',
																id: 'country_of_residence_id',
																required: true,
															}}
														>
															<MenuItem value=""><em>None</em></MenuItem>
															{countriesOfResidence.map(c => <MenuItem key={`country-${c.id}`} value={c.id}>{c.name}</MenuItem>)}
														</Select>
													</FormControl>

												</React.Fragment>
											)}

											<Paper elevation={0} style={{ paddingTop: 16 }}>
												<Grid container>
													<Grid item xs={12} sm={3}>
														<Button classes={{ root: classes.buttonStyle }} type="submit" disabled={isAuthenticating}>
															<Typography style={{ fontFamily: 'Nunito', textTransform: 'none', padding: 10, fontSize: '16', color: 'white' }}>
																{signinOrSignup === SIGNUP ? 'Sign Up' : 'Sign In'}
															</Typography>
														</Button>
													</Grid>
													<Grid item xs={12} sm={9} style={{ textAlign: 'center' }} >
														{this.props.session.isAuthenticating && <BusyIndicator />}
														<p>{this.state.errors && <span id="feedback" style={{ marginLeft: 16, lineHeight: '100%' }} >{this.state.errors}</span>}</p>
														{props.errors.name && <div id="feedback">{props.errors.name}</div>}
													</Grid>
												</Grid>

											</Paper>
										</form>

									</Paper>
								</React.Fragment>);
						}}
					/>

				</Grid>
			</Grid>
		);
	}
}

RegistrationForm.propTypes = {
	classes: PropTypes.object.isRequired,
};
export default withRouter(withStyles(styles)(inject('routing', 'countries', 'notifications', 'session')(observer(RegistrationForm))));