import React from 'react';
import AppCard from '../OptionSelector/AppCard';
import { withStyles } from '@material-ui/core/styles';
import { inject } from "mobx-react";
import configTheme from '../../config/theme';
import { Grid, Card, CardContent, Typography, Button, Badge } from '@material-ui/core';
import AccountIcon from '@material-ui/icons/AccountBox';
import MailIcon from '@material-ui/icons/Mail';
import InstitutionIcon from '@material-ui/icons/AccountBalance';
import ReferIcon from '@material-ui/icons/Favorite';
import TermsIcon from '@material-ui/icons/Gavel';

function NoboundIcon() {
	return <img alt="" src="https://www.nobound.io/favicon.ico" width="32" style={{ height: 32, width: 32, }}></img>;
}

const MessageBadge = withStyles(theme => ({
	badge: {
		backgroundColor: '#44b700'
	}
}))(Badge);

function BlockMenuUI(props) {
	const { classes } = props;
	const options = [
		{ slug: 'personal-details', order: 0, title: 'Personal Details', icon: AccountIcon, subTitle: '' },
		{ slug: 'notifications', order: 1, title: 'Your Messages', icon: MailIcon, subTitle: '' },
		{ slug: 'settlement-accounts', order: 2, title: 'Settlement Accounts', icon: InstitutionIcon, subTitle: '' },
		{ slug: 'refer-a-friend', order: 3, title: 'Refer a Friend', icon: ReferIcon, subTitle: '' },
		{ slug: 'coming-soon', order: 4, title: 'Coming Soon', icon: NoboundIcon, subTitle: '' },
		{ slug: 'terms', order: 5, title: 'Usage Terms', icon: TermsIcon, subTitle: '' },
	];
	const girdOptions = options.map((a, key) => (
		<Grid item xs={6} key={key}>
			<Card className={classes.card} component={Button} onClick={() => props.routing.push(`/${a.slug}`)}>
				<CardContent>

					{a.slug !== 'notifications' && <a.icon style={{ color: configTheme.primaryCTAColour }} />}

					{a.slug === 'notifications' && (
						props.messages.unread() ?
							(
								<MessageBadge badgeContent={props.messages.unread()} color="primary">
									<a.icon style={{ color: configTheme.primaryCTAColour }} />
								</MessageBadge>
							) :
							(
								<a.icon style={{ color: configTheme.primaryCTAColour }} />
							)
					)}
					
					<Typography variant="body1">{a.title}</Typography>
				</CardContent>
			</Card>
		</Grid>
	));

	return (
		<AppCard classes={classes}>
			<Grid container>
				{girdOptions}
				<Grid item xs={12}>
					<Card className={classes.logoutCard} component={Button} onClick={() => props.routing.push('/logout')}>
						<CardContent>
							<Typography variant="body1">Logout</Typography>
						</CardContent>
					</Card>
				</Grid>
			</Grid>
		</AppCard>
	);
}

const styles = theme => ({
	container: {
		display: 'flex',
		flexWrap: 'wrap',
	},
	paper: {
		// boxShadow: '0px 20px 40px rgba(0,0,0,0.06)',
		// padding: theme.spacing(2),
		textAlign: 'center'
	},
	card: {
		minWidth: '90%',
		height: 140,
		margin: theme.spacing(1),
		textTransform: 'capitalize',
		border: '1px solid #eee',
		// boxShadow: '0px 3px 10px 0px rgba(0,0,0,0.06)',
		boxShadow: 'initial',
	},
	logoutCard: {
		minWidth: '95%',
		// height: 35,
		margin: theme.spacing(1),
		textTransform: 'capitalize',
		border: '1px solid #eee',
		// boxShadow: '0px 3px 10px 0px rgba(0,0,0,0.06)',
		boxShadow: 'initial',
	},

});


export default withStyles(styles, { withTheme: true })(inject('routing', 'accounts', 'currencies', 'messages')(BlockMenuUI));