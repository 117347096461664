import React from 'react';
import InvestIcon from '../images/Eco';
import ExitIcon from '../images/Exit';
import Settings from '@material-ui/icons/Settings';
import FinancialInstitutionIcon from '@material-ui/icons/Business';
import BusinessCentre from '../images/Business_Centre';

import { AccountsOverviewContainer } from "../components/AccountsOverview";
import { DirectPaymentContainer } from "../components/DirectPayment";
import WalletIcon from '../images/Wallet';
import DashboardIcon from '../images/DashboardIcon';
import { LoginForm } from '../components/LoginForm';
import { DashboardHeaderPartnerSummary } from '../components/DashboardHeaderPartnerSummary';
import { FinancialInstitutionContainer } from '../components/FinancialInstitution';
import { InvestContainer } from '../components/Invest';
import { ProductContainer } from '../components/Product';
import { ProductInvestorContainer } from '../components/Product';
import { PortfolioContainer } from '../components/Portfolio';
import { AdminUIContainer } from '../components/AdminUIs';
import { LogoutContainer } from '../components/Logout';
import { AdminPostFundsContainer } from '../components/AdminUIs';
import { AccountActionsUIContainer } from '../components/AccountActions';
import { ExchangeContainer } from '../components/Exchange';
import { WithdrawUIContainer } from '../components/WithdrawUI';
import { AccountTransactionsUIContainer } from '../components/AccountTransactions';
import { BlockMenuUIContainer } from '../components/BlockMenu';
import { MessageUIContainer } from '../components/Messages';

import AccountCircle from '../images/Account';
import { SettlementAccountContainer } from '../components/SettlementAccount';
import { USER_TYPES } from '../stores/SessionStore';
import TermsContainer from '../components/YouContentUIs/TermsContainer';
import ComingSoonContainer from '../components/YouContentUIs/ComingSoonContainer';
import { ReferFriendContainer, ProfileEditContainer } from '../components/YouContentUIs';


let order = 0;
const pages = [
	{
		path: "/login",
		text: "Login",
		subtext: "Login and Registration",
		component: LoginForm,
		icon: <DashboardIcon color="#acb1b9" />,
		title: "Nobound.io - Nobound Investor App - Login and Registration",
		order: ++order,
		menu: false,
		hideHeader: true,
		access: [USER_TYPES.NONE],
	},
	{
		path: "/dashboard",
		headerComponent: DashboardHeaderPartnerSummary,
		component: AccountsOverviewContainer,
		icon: <DashboardIcon color="#acb1b9" />,
		title: "Nobound.io - Nobound Investor App - Dashboard",
		default: true,
		order: ++order,
		menu: true,
		text: 'Dashboard',
		access: [USER_TYPES.INVESTOR],
	},
	{
		path: "/dashboard",
		component: AdminUIContainer,
		icon: <Settings />,
		default: true,
		order: ++order,
		menu: true,
		text: 'Admin UI',
		access: [USER_TYPES.ADMIN],	
	},
	{
		path: "/wallet",
		text: 'Wallet',
		component: DirectPaymentContainer,
		icon: <WalletIcon color="#acb1b9" />,
		order: ++order,
		menu: true,
		access: [USER_TYPES.INVESTOR],
	},
	{
		path: "/fis",
		text: 'Investments',
		component: InvestContainer,
		icon: <FinancialInstitutionIcon />,
		order: ++order,
		menu: false,
		access: [USER_TYPES.INVESTOR],
	},
	{
		path: "/invest",
		text: 'Invest',
		component: ProductInvestorContainer,
		icon: <InvestIcon color="#acb1b9" />,
		order: ++order,
		menu: true,
		access: [USER_TYPES.INVESTOR],
	},
	{
		path: "/portfolio",
		text: 'Portfolio',
		component: PortfolioContainer,
		icon: <BusinessCentre color="#acb1b9" />,
		order: ++order,
		menu: true,
		access: [USER_TYPES.INVESTOR],
	},
	{
		path: "/account-actions",
		text: 'Wallet',
		component: AccountActionsUIContainer,
		icon: <Settings />,
		order: ++order,
		menu: false,
		access: [USER_TYPES.INVESTOR],
	},
	{
		path: "/exchange",
		text: 'Exchange',
		component: ExchangeContainer,
		icon: <Settings />,
		order: ++order,
		menu: false,
		access: [USER_TYPES.INVESTOR],
	},
	{
		path: "/withdraw",
		text: 'Withdraw',
		component: WithdrawUIContainer,
		icon: <Settings />,
		order: ++order,
		menu: false,
		access: [USER_TYPES.INVESTOR],
	},
	{
		path: "/account-transactions",
		text: 'Transactions',
		component: AccountTransactionsUIContainer,
		icon: <Settings />,
		order: ++order,
		menu: false,
		access: [USER_TYPES.INVESTOR],
	},
	{
		path: "/you",
		text: 'Profile',
		component: BlockMenuUIContainer,
		icon: <AccountCircle color="#acb1b9" />,
		order: ++order,
		menu: true,
		access: [USER_TYPES.INVESTOR],
	},
	{
		path: "/financial-institutions",
		text: 'FI Management',
		component: FinancialInstitutionContainer,
		icon: <Settings />,
		order: ++order,
		menu: true,
		optionButton: 'add',
		access: [USER_TYPES.ADMIN],
	},
	{
		path: "/products",
		text: 'Products',
		component: ProductContainer,
		icon: <Settings />,
		order: ++order,
		menu: true,
		optionButton: 'add',
		access: [USER_TYPES.ADMIN],
	},
	{
		path: "/post-transaction",
		text: 'Post Transaction',
		component: AdminPostFundsContainer,
		icon: <Settings />,
		order: ++order,
		menu: false,
		optionButton: 'add',
		access: [USER_TYPES.ADMIN],
	},
	{
		path: "/personal-details",
		text: 'Personal Details',
		component: ProfileEditContainer,
		icon: <Settings />,
		order: ++order,
		menu: false,
		access: [USER_TYPES.INVESTOR],
	},
	{
		path: "/notifications",
		text: 'Your Messages',
		component: MessageUIContainer,
		icon: <Settings />,
		order: ++order,
		menu: false,
		access: [USER_TYPES.INVESTOR, USER_TYPES.ADMIN],
	},
	{
		path: "/settlement-accounts",
		text: 'Settlement Accounts',
		component: SettlementAccountContainer,
		icon: <Settings />,
		order: ++order,
		menu: false,
		access: [USER_TYPES.INVESTOR],
	},
	{
		path: "/refer-a-friend",
		text: 'Refer a Friends',
		component: ReferFriendContainer,
		icon: <Settings />,
		order: ++order,
		menu: false,
		access: [USER_TYPES.INVESTOR],
	},
	{
		path: "/coming-soon",
		text: 'Coming Soon',
		component: ComingSoonContainer,
		icon: <Settings />,
		order: ++order,
		menu: false,
		access: [USER_TYPES.INVESTOR],
	},
	{
		path: "/terms",
		text: 'Usage Terms',
		component: TermsContainer,
		icon: <Settings />,
		order: ++order,
		menu: false,
		access: [USER_TYPES.INVESTOR],
	},
	{
		path: "/logout",
		text: 'Logout',
		component: LogoutContainer,
		icon: <ExitIcon color="#acb1b9" />,
		order: ++order,
		menu: true,
		access: [USER_TYPES.ADMIN, USER_TYPES.INVESTOR],
	},
];

export default { pages };

export function getPersonTypeMenu(person) {
	let id = (person && person.person_type_id) || 0;
	// console.log(`getPersonTypeMenu: user type is ${USER_TYPES[id]}`)
	let p = pages.filter(a => a.access.indexOf(USER_TYPES[id]) >= 0);
	return { pages: p };
}
