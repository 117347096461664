import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { inject, observer } from "mobx-react";
import HUE from '@material-ui/core/colors/grey';
import configTheme from '../../config/theme';
import ReferFriendUI from './ReferFriendUI';
import { sendNotification } from '../../libs/common';
const accountTileNameText = HUE[400];

class ReferFriendContainer extends React.Component {
	state = {
		email: ''
	}

	onChange = (event) => {
		const email = ('' + event.target.value).toLowerCase();
		// TODO use regex
		const isValid = email.indexOf('@') >= 1 && email.indexOf('.') >= 3 && 'qwertyuiopasdfghjklzxcvbnm1234567890+'.indexOf(email[email.length - 1]) >= 0;
		this.setState({ email, isValid });
	}

	onSubmit = async (event) => {
		if (this.state.isValid) {
			const res = await this.props.notifications.sendOther(`refer a friend: ${this.state.email}`);
			if (res) {
				sendNotification(`Thank you!`, 'success');
				this.props.routing.replace('you');
			}
		}
	}

	render() {
		const { classes } = this.props;
		const { onSubmit, onChange } = this;
		const { isValid, email } = this.state;
		return <ReferFriendUI {...{ classes, onSubmit, isValid, onChange, email }} />;
	}
}

const styles = theme => ({
	menuButton: {
		marginLeft: 12,
		marginRight: 12,
	},
	paper: {
		padding: theme.spacing(2),
		textAlign: 'center',
		color: accountTileNameText,
	},
	button: {
		...configTheme.lightButton,
		marginTop: theme.spacing(2),
	},
	icon: {
		color: 'rgb(130,202,64)',
		fontSize: 100
	},
	textbox: {
		marginTop: theme.spacing(2),
	},
	text: {
		color: 'black',
		textAlign: 'center',
		padding: theme.spacing(2),
	}
});

export default withStyles(styles, { withTheme: true })(inject('notifications', 'routing')(observer(ReferFriendContainer)));
