import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { inject, observer } from "mobx-react";
import DirectPaymentDetails from './DirectPaymentDetails';
import { OptionSelector } from '../OptionSelector';
import { OptionSelector2 } from '../OptionSelector';
import Flag from 'react-world-flags';
import { toCamelCase, urlToHashParams, hashParamsToURL } from '../../libs/common';

import HUE from '@material-ui/core/colors/grey';
import configTheme from '../../config/theme';
const accountTileNameText = HUE[400];

const styles = theme => ({
	menuButton: {
		marginLeft: 12,
		marginRight: 12,
	},
	paper: {
		padding: theme.spacing(2),
		textAlign: 'center',
		color: accountTileNameText,
	},
	button1: configTheme.lightTrypticButton,
	button2: configTheme.lightButton,
	icon: {
		color: 'rgb(130,202,64)'
	},
});

class DirectPaymentContainer extends React.Component {
	state = {
		localHistory: [window.location.href.split('?')[0]],
		baseURL: window.location.href.split('?')[0],
		basePath: 'wallet'
	}

	async onSendFundsClick(currency) {
		const res = await this.props.notifications.send(`${currency || 'GBP'}`);
		if (res) {
			this.props.routing.push('dashboard');
		}
	}
	onBackToDashboard = () => {
		this.props.routing.push('dashboard');
	}
	onBackToCurrencySelector = () => {
		this.props.routing.push('wallet');
	}
	navigateTo = (key, value) => {
		const hashKey = toCamelCase(key);
		const hashParams = urlToHashParams(window.location.hash);

		hashParams[hashKey] = value;

		const currentState = hashParamsToURL(this.state.baseURL, hashParams);

		window.history.replaceState(null, null, currentState);
		this.setState({ localHistory: [...this.state.localHistory, currentState] },
		);
	}
	navigateBack = () => {

		if (this.state.localHistory.length) {
			let value = this.state.localHistory.pop();

			if (value === window.location.href) {
				return this.navigateBack();
			}

			window.history.replaceState(null, null, value);
			this.setState({ localHistory: [...this.state.localHistory, value] },
			);
		} else {
		}
	}

	async componentDidMount() {
		return Promise.all(['currencies'].map(a => this.props[a].check()));
	}

	render() {
		const { classes } = this.props;

		let accountNumber = 'account not setup';
		if (this.props.session.user && this.props.session.user.attributes) {
			if (this.props.session && this.props.session.user && this.props.session.user.attributes && this.props.session.user.attributes['custom:noboundAccountId']) {
				accountNumber = this.props.session.user.attributes['custom:noboundAccountId'];
			}
			if (this.props.session && this.props.session.person && this.props.session.person.unique_account_id) {
				accountNumber = this.props.session.person.unique_account_id;
			}
		}

		const hashParams = urlToHashParams(window.location.hash);

		if (!hashParams.loadOption) {
			const options = [
				{ id: 1, name: 'Direct Transfer', slug: 'direct-transfer', active: true },
				{ id: 2, name: 'Debit / Credit Card', slug: 'debit-credit-card', active: false },
				{ id: 3, name: 'Apple Pay / Google Pay', slug: 'apple-google-pay', active: false },
				{ id: 4, name: 'PayPal', slug: 'paypal', active: false },
			];
			const title = 'Add Funds';
			const subTitle = 'Choose how to add funds';

			return <OptionSelector classes={classes} options={options} title={title} subTitle={subTitle} hashVariable="load-option" navigateTo={this.navigateTo} />;
		}

		const currencies = this.props.currencies.data;

		if (!hashParams.currency) {
			const options = currencies.map(a => ({ id: a.iso3, text: a.name, country_iso3: a.country_iso3, disabled: !a.account_details }));
			const currenciesMap = options.reduce((a, c) => Object.assign(a, { [c.id]: c.country_iso3 }), {});
			const iconFn = (iso3) => {
				return <Flag code={(currenciesMap[iso3]).trim()} width="32" />;
			}
			return <OptionSelector2 classes={classes} onBack={this.navigateBack} options={options} title={'Direct Transfer'} subTitle={'Transfer funds directly from your bank'} subTitleText={'Select Transfer Currency'} hashVariable="currency" iconFn={iconFn} navigateTo={this.navigateTo} />;
		}

		let selectedCurrency = false, accountDetails;
		const currencyCode = hashParams.currency;
		accountDetails = currencies.find(v => v.iso3 === currencyCode);
		if (accountDetails) {
			selectedCurrency = currencyCode;
		} else {
			accountDetails = {
				iso3: currencyCode,
				account_details: 'Sorry, we could not find that currency in our system.'
			};
		}

		return <DirectPaymentDetails details={accountDetails.account_details} title={hashParams.currency} accountNumber={accountNumber} onBack={this.navigateBack} classes={classes} onSendFundsClick={() => this.onSendFundsClick(selectedCurrency)} />;
	}
}

export default withStyles(styles, { withTheme: true })(inject('routing', 'session', 'notifications', 'currencies')(observer(DirectPaymentContainer)));