import { decorate, action, observable } from 'mobx';
import GenericStore from './GenericStore';

class CurrencyStore extends GenericStore {
	apiName = 'currencies';
	singular = 'currency';
	plural = 'currencies';
	noRate = true;

	getRate = async ({ currencyIdFrom, currencyIdTo }) => {
		try {
			this.isBusy = true;
			this.noRate = true;
			let path = `/${this.apiName}/${currencyIdFrom}/exchange/${currencyIdTo}`;
			let message = await this.getInitialisedMessage();
			const response = await this.API.get(this.apiName, path, message);
			this.isBusy = false;
			if (response.data.rate){
				this.noRate = false;
				return response.data.rate;
			}
		} catch (error) {
			this.isBusy = false;
			this.noRate = true;
			return {};
		}
	}
}

decorate(CurrencyStore, {
	noRate: observable,
	getRate: action,
});

export default new CurrencyStore();
