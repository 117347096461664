import { decorate, computed } from 'mobx';
import GenericStore from './GenericStore';
import Person from './models/Person';
// import { SetS3Config } from "../index";
import { Storage } from "aws-amplify";

class PersonStore extends GenericStore {
	model = Person;

	apiName = 'person';
	singular = 'person';
	plural = 'persons';

	fullname = () => {
		return `${this.data.first_name} ${this.data.last_name}`;
	}
	get person(){
		return this.data[0] || {};
	}
	uploadDocument = async (file, fileType) => {
    try {
      this.isBusy = true;
      const stored = await Storage.put(`${this.data.sub}/${fileType}-${Date.now()}-${file.name}`, file, {
        contentType: file.type
      });
      this.loading = false;
			return stored.key;

    } catch (error) {
			this.isBusy = false;
			this.processErrorCode(error);
    }
	}
}

decorate(PersonStore, {
	person: computed,
});

export default new PersonStore();
