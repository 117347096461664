import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import Badge from '@material-ui/core/Badge';
import { withRouter } from "react-router";
import DashboardIcon from '../../images/DashboardIcon';
import BusinessCentre from '../../images/Business_Centre';
import InvestIcon from '../../images/Eco';
import AccountCircle from '../../images/Account';
import { withStyles } from '@material-ui/core/styles';
import { inject, observer } from "mobx-react";
import cssTheme from '../../config/theme';
import WalletIcon from '../../images/Wallet';

const useStyles = makeStyles({
	root: {
		width: '100%',
		minWidth: 'inherit',
	},
	selected: {
		color: cssTheme.primaryCTAColour
	}
});

const urls = ['dashboard', 'portfolio', 'invest', 'account-actions', 'you'];

const MessageBadge = withStyles(theme => ({
	badge: {
		backgroundColor: '#44b700'
	}
}))(Badge);


function SimpleBottomNavigation(props) {
	const classes = useStyles();

	let currentPosition = urls.indexOf(props.location.pathname.substr(1)) || 0;
	let value = (currentPosition < 0) ? 0 : currentPosition;

	return (
		<React.Fragment>

			<style dangerouslySetInnerHTML={{ __html: 'span.MuiBottomNavigationAction-label.Mui-selected {color:green} .MuiBottomNavigationAction-root {min-width: inherit !important}' }} />
			<BottomNavigation
				value={value}
				onChange={(event, newValue) => {
					const url = urls[newValue];
					props.routing.push(url);
					props.messages.check();
				}}
				showLabels
				classes={{ root: classes.root }}
			>
				<BottomNavigationAction label="Home" icon={<DashboardIcon color={value === 0 ? cssTheme.primaryCTAColour : null} />} />
				<BottomNavigationAction label="Portfolio" icon={<BusinessCentre color={value === 1 ? cssTheme.primaryCTAColour : null} />} />
				<BottomNavigationAction label="Invest" icon={<InvestIcon color={value === 2 ? cssTheme.primaryCTAColour : null} />} />
				<BottomNavigationAction label="Wallet" icon={<WalletIcon color={value === 3 ? cssTheme.primaryCTAColour : null} />} />
				<BottomNavigationAction label="You" icon={
					props.messages.unread() ?
						(
							<MessageBadge badgeContent={props.messages.unread()} color="primary">
								<AccountCircle color={value === 4 ? cssTheme.primaryCTAColour : null} />
							</MessageBadge>
						) :
						<AccountCircle color={value === 4 ? cssTheme.primaryCTAColour : null} />
				} />
			</BottomNavigation>
		</React.Fragment>
	);
}

export default withRouter(inject('routing', 'messages')(observer(SimpleBottomNavigation)));
