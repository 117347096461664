import { observable, action, decorate, runInAction, computed } from 'mobx';
import { Auth, API } from "aws-amplify";

export const USER_TYPES = {
	ADMIN: 'ADMIN',
	INVESTOR: 'INVESTOR',
	NONE: 'NONE',
	0: 'NONE',
	1: 'ADMIN',
	2: 'ADMIN',
	4: 'INVESTOR',
};
class SessionStore {
	isAuthenticated = false;
	isAuthenticating = false;
	isBusy = true;
	user = null;
	person = null;
	localStorage = (window && window.localStorage) || {};

	constructor() {
		if (this.localStorage.noboundUser) {
			this.setUser(JSON.parse(this.localStorage.noboundUser));
		}
	}

	async getInitialisedMessage() {
		const user = await Auth.currentAuthenticatedUser();
		const token = user.signInUserSession.idToken.jwtToken;
		return {
			headers: { Authorization: token },
			response: true,
			body: {}
		};
	}

	async getPerson() {
		let path = `/person/me`;
		let message = await this.getInitialisedMessage();

		return API.get('person', path, message).then(response => {
			return response.data;
		}).catch(error => {
			console.log(error);
			return {};
		});
	}

	//@action.bind
	async updateCurrentUserInfo() {
		try {
			this.setIsBusy(true);
			const userInfo = await Auth.currentUserInfo();
			const person = await this.getPerson();
			runInAction(() => {
				this.setUser(userInfo);
				this.setPerson(person);
				this.setIsBusy(false);
			});
		} catch (e) {
			console.log({ errorInFunction_updateCurrentUserInfo: e });
			runInAction(() => {
				this.setIsBusy(false);
			});
			return e.message;
		}
	}

	//@action.bind
	async checkLogin() {
		try {
			this.setIsAuthenticating(true);
			await Auth.currentSession();
			runInAction(() => {
				this.updateCurrentUserInfo();
				this.setIsAuthenticated(true);
				this.setIsAuthenticating(false);
			});
		} catch (e) {
			console.log('sessionStore:checkLogin', e);
			runInAction(() => {
				this.setIsAuthenticated(false);
				this.setIsAuthenticating(false);
			});
		}
	}

	setIsBusy(isBusy) {
		this.isBusy = isBusy;
	}

	setIsAuthenticating(isAuthenticating) {
		this.isAuthenticating = isAuthenticating;
	}

	setIsAuthenticated(isAuthenticated) {
		this.isAuthenticated = isAuthenticated;
	}

	setUser(user) {
		this.user = user;
		if (!user || JSON.stringify(user) === '{}') {
			delete this.localStorage.noboundUser;
		} else {
			this.localStorage.noboundUser = JSON.stringify(this.user);
		}
	}

	setPerson(person) {
		this.person = person;
		if (!person || JSON.stringify(person) === '{}') {
			delete this.localStorage.person;
		} else {
			this.localStorage.person = JSON.stringify(this.person);
		}
	}

	//@action.bind
	async doLogin(values) {
		const { email, password } = values;
		try {
			this.setIsAuthenticating(true);
			await Auth.signIn(email, password);
			runInAction(async () => {
				this.setIsAuthenticating(false);
				this.setIsAuthenticated(true);
				this.updateCurrentUserInfo();
			});
			return false;
		} catch (e) {
			runInAction(() => {
				this.setIsAuthenticating(false);
				this.setIsAuthenticated(false);
			});
			console.log({ errorInFunction_doLogin: e });
			return e.message;
		}
	}

	//@action.bind 
	async doLogout() {
		this.setUser();
		try {
			this.setIsAuthenticating(true);
			await Auth.signOut();
			runInAction(() => {
				this.setUser(null);
				this.setIsAuthenticating(false);
				this.setIsAuthenticated(false);
				window.location.pathname = '/';
			});
			return false;
		} catch (e) {
			runInAction(() => {
				this.setIsAuthenticating(false);
			});
			console.log({ errorInFunction_doLogout: e });
			return e.message;
		}
	}

	//@action.bind
	async doRegister(values) {

		this.setIsAuthenticating(true);

		try {
			const newUser = await Auth.signUp({
				username: values.email,
				password: values.password,
				attributes: {
					family_name: values.lastName,
					given_name: values.firstName,
					email: values.email,
					'custom:country_of_origin': '' + values.country_of_origin_id,
					'custom:country_of_residence': '' + values.country_of_residence_id,
				}
			});
			runInAction(() => {
				this.setUser(newUser);
				this.setIsAuthenticating(false);
			});
			return false;

		} catch (e) {
			runInAction(() => {
				this.setIsAuthenticating(false);
			})
			if (e.code === 'NotAuthorizedException') {
				console.log('custom attributes may not have permissions set for this client app.');
			}
			return e.message;
		}
	}

	//@action.bind 
	doResetPassword() {

	}

	//@compute
	get isAdmin(){
		return this.person && this.person.person_type_id && USER_TYPES[this.person.person_type_id] === 'ADMIN';
	}
}

decorate(
	SessionStore, {
	isAuthenticated: observable,
	isAuthenticating: observable,
	user: observable,
	person: observable,
	isBusy: observable,

	isAdmin: computed,

	checkLogin: action,
	doLogin: action,
	doLogout: action,
	doRegister: action,
	updateCurrentUserInfo: action,
	doResetPassword: action,
	setPerson: action,
	setUser: action,
}
)

export default new SessionStore();