import React from 'react';
import { Grid, FormControl, FormControlLabel } from '@material-ui/core';
import { Typography, Table, TableBody, TableCell, TableRow } from '@material-ui/core';
import { Checkbox } from '@material-ui/core';
import { green } from '@material-ui/core/colors';
import { withStyles } from '@material-ui/core/styles';
import { Formik, Field } from 'formik';
import { inject, observer } from "mobx-react";
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { numberWithCommas } from '../../libs/common';
import { AppCard, Header } from '../OptionSelector';
import Titles from '../OptionSelector/Titles';
import configTheme from '../../config/theme';

const GreenCheckbox = withStyles({
	root: {
		color: green[400],
		'&$checked': {
			color: green[600],
		},
	},
	checked: {},
})(props => <Checkbox color="default" {...props} />);

class PortfolioItemEdit extends React.Component {
	state = {

	}
	render() {
		const { classes, fis, product, currency, initialValues } = this.props;
		const { subTitle, subTitleText, title, onBack, onSubmit } = this.props;
		const noTermsText = 'No terms here!';

		return (
			<React.Fragment>
				<AppCard classes={classes} topBorderColour={configTheme.portfolioStatusColours[initialValues.status]}>
					{Header(onBack, undefined, title, 'default')}
					{Titles(undefined, `Status: ${initialValues.status}`, { subTitleTextColour: configTheme.portfolioStatusColours[initialValues.status] })}
					<Grid item xs={12}>
						{!subTitle ? '' :
							<Typography style={{ fontSize: 16, fontWeight: 500 }}>
								{subTitle}
							</Typography>
						}
						{!subTitleText ? '' :
							<Typography color="textSecondary" style={{ fontSize: 16, fontWeight: 500, color: 'darkgrey' }}>
								{subTitleText}
							</Typography>
						}
					</Grid>

					<Grid item xs={12}>

						<Table padding="none">
							<TableBody>
								<TableRow style={{ height: 48 }}>
									<TableCell><img style={{ height: 32, width: 32 }} alt="" src={fis.issuing_fi_url_logo} /></TableCell>
									<TableCell style={{ textAlign: 'left', fontWeight: '600', fontSize: '16px', border: 'solid lightgrey', borderWidth: '0px 1px 1px 0px' }}>{product.name}</TableCell>
									<TableCell style={{ textAlign: 'center', fontWeight: '600', fontSize: '16px', border: 'solid lightgrey', borderWidth: '0px 1px 1px 0px' }}>{product.interest_rate}%</TableCell>
									<TableCell style={{ textAlign: 'right', fontWeight: '600', fontSize: '16px', border: 'solid lightgrey', borderWidth: '0px 0px 1px 1px' }}>{product.tenor} days</TableCell>
								</TableRow>
							</TableBody>
						</Table>
						<Table padding="none">
							<TableBody>
								<TableRow style={{ height: 48 }}>
									<TableCell colSpan="2" style={{ textAlign: 'left', fontWeight: '600', fontSize: '16px', color: 'darkgrey', border: 'solid lightgrey', borderWidth: '0px 1px 1px 0px' }}>{fis.name}</TableCell>
									<TableCell style={{ textAlign: 'center', fontWeight: '600', fontSize: '16px', color: 'darkgrey', border: 'solid lightgrey', borderWidth: '0px 1px 1px 0px' }}>{currency.iso3}</TableCell>
									<TableCell style={{ textAlign: 'right', fontWeight: '600', fontSize: '16px', color: '#82CA40', border: 'solid lightgrey', borderWidth: '0px 0px 1px 1px' }}>{numberWithCommas(initialValues.amount)}</TableCell>
								</TableRow>
							</TableBody>
						</Table>
						<Table padding="none">
							<TableBody>
								<TableRow style={{ height: 48 }}>
									<TableCell style={{ fontSize: '16px' }}>Maturity Date:</TableCell>
									<TableCell align="right" style={{ fontSize: '16px' }}>{initialValues.onMaturity.dateOfMaturity}</TableCell>
								</TableRow>
								<TableRow style={{ height: 48 }}>
									<TableCell style={{ fontSize: '16px' }}>Return on Maturity:</TableCell>
									<TableCell align="right" style={{ fontSize: '16px' }}>{initialValues.onMaturity.returnOnMaturity}</TableCell>
								</TableRow>
								<TableRow style={{ height: 48 }}>
									<TableCell style={{ fontSize: '16px' }}>Less local tax:</TableCell>
									<TableCell align="right" style={{ fontSize: '16px' }}>{initialValues.onMaturity.amountOfTax}</TableCell>
								</TableRow>
								<TableRow style={{ height: 48 }}>
									<TableCell style={{ fontSize: '16px' }}>Total value on Maturity:</TableCell>
									<TableCell align="right" style={{ fontSize: '16px' }}>{initialValues.onMaturity.amountOnMaturity}</TableCell>
								</TableRow>
							</TableBody>
						</Table>

						<Formik
							initialValues={initialValues}
							onSubmit={(values, actions) => {
								onSubmit(values);
							}}
							render={(props) => {
								return (
									<form onSubmit={props.handleSubmit}>

										<ExpansionPanel style={{}}>
											<ExpansionPanelSummary
												expandIcon={<ExpandMoreIcon />}
												aria-controls="panel1a-content"
												id="panel1a-header">
												<Typography className={classes.heading}>Terms and Conditions</Typography>
											</ExpansionPanelSummary>
											<ExpansionPanelDetails>
												<Typography style={{ textAlign: 'left' }} dangerouslySetInnerHTML={{ __html: product.terms_and_conditions || noTermsText }} />
											</ExpansionPanelDetails>
										</ExpansionPanel>

										<ExpansionPanel style={{}}>
											<ExpansionPanelSummary
												expandIcon={<ExpandMoreIcon />}
												aria-controls="panel1a-content"
												id="panel1a-header">
												<Grid container justify="space-between">
													<Grid item>
														<Typography className={classes.heading}>Reinvest on Maturity?</Typography>
													</Grid>
													<Grid item>
														<Typography>{props.values.instruction_on_maturity_id ? 'Yes!' : 'No'}</Typography>
													</Grid>
												</Grid>
											</ExpansionPanelSummary>
											<ExpansionPanelDetails>

												<Field
													name="instruction_on_maturity_id"
													id="instruction_on_maturity_id"
													render={() =>
														<FormControl error={!!props.errors['instruction_on_maturity_id']}>
															<FormControlLabel
																style={{ paddingBottom: 0 }}
																control={
																	<GreenCheckbox
																		checked={parseInt(props.values.instruction_on_maturity_id, 10) === 1}
																		onChange={(e) => {
																			props.handleChange(e);
																			setTimeout(props.submitForm, 0);
																		}}
																		onBlur={props.handleBlur}
																		name="instruction_on_maturity_id"
																		id="instruction_on_maturity_id"
																	/>}
																label="Reinvest on maturity?"
															/>
														</FormControl>}
												/>

											</ExpansionPanelDetails>
										</ExpansionPanel>
									</form>
								);
							}}
						/>
					</Grid>

				</AppCard>
			</React.Fragment>
		);
	}
}

export default (inject('routing')(observer(PortfolioItemEdit)));