import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import CssBaseline from '@material-ui/core/CssBaseline';
import { Link } from "react-router-dom";
import logo from '../../images/nobound-logo-v2.png';

import { getPersonTypeMenu } from '../../config/sitemap';
import { withRouter } from "react-router";
import { inject, observer } from "mobx-react";
import { Routes } from '../Routes';
import cssTheme from '../../config/theme';
import BottomNavigation from './BottomNavigation';
import { applyAdjustmentOnMutation } from '../../libs/common';

const drawerWidth = 240;

const styles = theme => ({
	root: {
		display: 'flex',
		backgroundColor: '#ffffff',
	},
	grow: {
		flexGrow: 1,
	},
	appBarBottom: {
		width: '100%',
		boxShadow: '0px 6px 20px rgba(0,0,0,0.1)',
		backgroundColor: '#ffffff',
		padding: theme.spacing(1),
		top: 'auto',
		bottom: 0,
	},
	appBarTop: {
		width: '100%',
		boxShadow: '0px 6px 20px rgba(0,0,0,0.1)',
		backgroundColor: '#ffffff',
		padding: theme.spacing(1),
		top: 0,
	},
	menuButton: {
		marginLeft: 12,
		marginRight: 36,
	},
	hide: {
		display: 'none',
	},
	drawer: {
		width: drawerWidth,
		flexShrink: 0,
		whiteSpace: 'nowrap',
		zIndex: 1201,
		boxShadow: '0 0 15px rgba(0,0,0,0.06)',
	},
	drawerOpen: {
		width: drawerWidth,
		transition: theme.transitions.create('width', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
	},
	drawerClose: {
		transition: theme.transitions.create('width', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
		overflowX: 'hidden',
		width: theme.spacing(7) + 1,
		[theme.breakpoints.up('sm')]: {
			width: theme.spacing(9) + 1,
		},
	},
	toolbar: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-end',
		margin: 8,
		height: 56,
	},
	contentAuthenticated: {
		flexGrow: 1,
		backgroundColor: '#f8f8f8',
	},
	contentUnauthenticated: {
		flexGrow: 1,
		backgroundColor: '#f8f8f8',
		marginTop: 72,
	},
	buttonRoot: {
		color: '#91969f',
		backgroundColor: '#f4f4f4',
	},
	badgeMail: {
		top: -8,
		left: 4,
		width: 18,
		height: 18,
		backgroundColor: '#1585ff',
	},
	badgeNotification: {
		top: -8,
		left: 4,
		width: 18,
		height: 18,
		backgroundColor: '#ff7473',
	},
	divider: {
		display: 'inline-block',
		border: '1px solid #e5e8ee',
		paddingLeft: 32,
		borderTop: 0,
		borderBottom: 0,
		marginRight: 32,
		paddingRight: 16,
	},
	listText: {
		color: '#acb1b9',
		fontFamily: 'Nunito',
		// height: 32,
	},
	mailBadgeMobile: {
		top: -20,
		left: -5,
	},
	mailBadgeBackgroundColour: {
		backgroundColor: cssTheme.primaryCTAColour,
	},
	alertBadgeMobile: {
		top: -20,
		left: 30,
		color: cssTheme.primaryCTAColour,
	},
	alertBadgeBackgroundColour: {
		backgroundColor: 'black',
	},
});

class MiniDrawer extends React.Component {
	wixdone = false;
	constructor(props) {
		super(props);
		this.props.session.checkLogin();
	}

	componentDidMount() {
		const newCSS = 'top: 3px; box-sizing: border-box; cursor: pointer; font-size: 25px; position: fixed; height: 61px; min-width: 61px; max-width: 61px; text-align: center; transition: all 0.2s ease-out 0s; fill: currentcolor; vertical-align: sub; z-index: 9; background-color: rgb(255, 255, 255); color: rgb(68, 88, 104); border-radius: 30px; right: 15px; left: auto; bottom: initial;';
		const elementId = 'open-launcher-icon';
		const timeoutValue = 5000;
		applyAdjustmentOnMutation({ newCSS, elementId, timeoutValue });
		this.props.messages.check();
	}

	render() {
		const { classes, theme, session } = this.props;
		const isAuthenticated = session.isAuthenticated;
		const sitemap = getPersonTypeMenu(session.person);
		const isAdmin = session.isAdmin;

		return (
			<div className={classes.root}>
				<CssBaseline />
				<style>{`body,html {background-color:#f8f8f8;}`}</style>
				<AppBar
					position="fixed"
					className={isAuthenticated ? classes.appBarBottom : classes.appBarTop}>
					{!isAuthenticated && (
						<Toolbar disableGutters={true}>
							<Link to="/"><img alt="nobound invest logo" src={logo} style={{ height: 50, marginLeft: `-${theme.spacing(1)}px` }} /></Link>
							<div className={classes.grow} />
						</Toolbar>)}
					{isAuthenticated && !isAdmin && <BottomNavigation />}
				</AppBar>
				<main className={isAuthenticated ? classes.contentAuthenticated : classes.contentUnauthenticated}>
					<Routes sitemap={sitemap} />
					<div className={classes.toolbar} />
				</main>
			</div>
		);
	}
}

MiniDrawer.propTypes = {
	classes: PropTypes.object.isRequired,
	theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(withRouter(inject('routing', 'session', 'notifications', 'messages')(observer(MiniDrawer))));