
export function sendNotification(message, type = 'success', options) {
	if (options && options.noNotifications) return null;
	window.dispatchEvent(new CustomEvent('nbmessage', { detail: { message, type } }));
}

export function toCamelCase(value) {
	return value.replace(/-([a-z])/g, function (g) { return g[1].toUpperCase(); });
}

export function toKebabCase(string) {
	return string.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase();
}

export function urlToHashParams(hash) {
	let hashObject = {};
	if (hash.indexOf('=') > 0) {
		hash.split('?')[1].split('&').forEach(param => {
			let split = param.split('=');
			hashObject[toCamelCase(split[0])] = split[1];
		})
	}
	return hashObject;
}

export function hashParamsToURL(baseURL, values) {
	const params = [];
	Object.keys(values).forEach(key => params.push(`${toKebabCase(key)}=${values[key]}`));
	const hashParams = '#?' + params.join('&');
	return baseURL.split('#')[0] + hashParams;
}

export function numberWithCommas(x) {
	return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function formatValue(value, format = 'i') { // i-integer, d-decimal, f-fraction-only
	let r = value / 100;
	if (format==='i') return parseInt(r);
	if (format==='d') return r.toFixed(2);
	if (format==='f') return r.toFixed(2).split('.')[1];
	return formatValue(value);
}


export function timestamp() {
	let d = new Date();
	d.setMinutes(d.getMinutes() + -d.getTimezoneOffset());
	return d.toISOString().replace('T', ' ').split('.')[0];
}

export function arrayToMap(array, key) {
	let a = {}
	array.forEach(b => a[b.id] = b);
	return a;
}

export function arrayToIdx(array, idxField, valueField) {
	if (!array) return {};
	return array.reduce((a, c) => Object.assign(a, { [c[idxField]]: c[valueField] }), {});
}

export function applyAdjustmentOnMutation({ newCSS, elementId, timeoutValue }) {
	let wixdone = false;
	function subscriber(mutations) {
		if (wixdone) {
			observer.disconnect();
			return;
		}
		let found;
		mutations.some(a => a.target.id === elementId ? found = a.target : false);
		if (!found) return;
		wixdone = true;
		found.parentNode.style = newCSS;
	}
	const observer = new MutationObserver(subscriber);
	const config = { attributes: true, subtree: true };
	observer.observe(document.body, config);
	window.setTimeout(() => {
		observer.disconnect();
	}, timeoutValue);
}

export function calculateEarnings(amount, product) {
	const yearlyEarnings = (parseInt(amount) / 100) * parseFloat(product.interest_rate);
	const yourEarnings = (yearlyEarnings / 365) * product.tenor;
	return parseInt(yourEarnings);
};

export function calculatePortfolioTotals(portfolioItems) {
	let returnsEarned = 0;
	let returnsDue = 0;
	let returnsTotal = 0;
	let currency = '₦';

	portfolioItems.forEach(a => {
		const calculatedEarnings = calculateEarnings(a.amount, a.productJSON);;

		// returnsEarned
		if (['LIQUIDATED', 'MATURED'].indexOf(a.status) >= 0) {
			returnsEarned += calculatedEarnings;
		}

		// returnsDue
		if (['BOOKED', 'PROCESSING', 'SUBMITTED'].indexOf(a.status) >= 0) {
			returnsDue += calculatedEarnings;
		}

		// portfolio balance
		if (['SUBMITTED', 'BOOKED', 'PROCESSING', 'MATURED'].indexOf(a.status) >= 0) {
			returnsTotal += calculatedEarnings + parseInt(a.amount);
		}

	});
	return { returnsEarned, returnsDue, returnsTotal, currency };
}

let id = 0;
export function nextId(){
	return ++id;
}

export function getBaseLocation() {
	return window.location.href.split('?')[0];
}
