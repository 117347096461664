import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Grid, Typography, Button, TextField, MenuItem, FormControl } from '@material-ui/core'
import configTheme from '../../config/theme';
import AppCard from '../OptionSelector/AppCard';
import { Header } from '../OptionSelector';
import { Progress1 } from '../ProgressIndicators';

function ExchangeUI(props) {
	const { theme, classes, onBack, amountFromSet, amountFrom, amountToSet, amountTo, exchangeCurrencies, currencyFrom, currencyTo, rate, fee, feeCurrencySymbol, onSubmit } = props;
	const isLoading = props.currencies.isBusy;
	const noRate = props.currencies.noRate;
	const isExchangeSubmitting = props.accounts.isExchangeSubmitting;

	return (
		<AppCard classes={classes}>
			{Header(onBack, null, 'Exchange')}
			<Grid item xs={12}>
				<Typography variant="h6" align="center">Balance: {props.balance}</Typography>
			</Grid>
			{isLoading && <Progress1 text="Gettting Rates" />}

			{!isLoading && noRate && <Typography align="center" style={{ width: '100%', paddingTop: theme.spacing(2) }}>No rates currently available</Typography>}

			{!isLoading && !noRate &&
				<React.Fragment>
					<Grid item xs={8}>
						<FormControl
							margin="normal"
							fullWidth
							required
						>
							<TextField
								type="number"
								InputProps={{ type: 'number' }}
								autoComplete='off'
								id="outlined-select-currency"
								label="Convert"
								className={classes.textField}
								value={amountFrom}
								onChange={a => amountFromSet(a.target.value)}
								margin="normal"
								variant="outlined"
							>
							</TextField>
						</FormControl>
					</Grid>
					<Grid item xs={4}>
						<FormControl
							margin="normal"
							fullWidth
							required
						>
							<TextField
								disabled
								id="outlined-select-currency"
								select
								label="From"
								className={classes.textField}
								value={currencyFrom}
								onChange={(a) => null}
								SelectProps={{
									MenuProps: {
										className: classes.menu,
									},
									name: 'currencyFrom'
								}}
								margin="normal"
								variant="outlined"
								name="currencyFrom"
							>
								{exchangeCurrencies.map(option => (
									<MenuItem key={option.id} value={option.id}>
										{option.label}
									</MenuItem>
								))}
							</TextField>
						</FormControl>
					</Grid>

					<Grid item xs={6}>
						<Typography align="center">Rate: {rate}</Typography>
					</Grid>
					<Grid item xs={6}>
						<Typography align="center">Fee: {feeCurrencySymbol}{fee}</Typography>
					</Grid>

					<Grid item xs={8}>
						<FormControl
							margin="normal"
							fullWidth
							required
						>
							<TextField
								type="number"
								InputProps={{ type: 'number' }}
								autoComplete='off'
								disabled
								id="outlined-select-currency"
								label=""
								className={classes.textField}
								value={amountTo}
								onChange={a => amountToSet(a.target.value)}
								margin="normal"
								variant="outlined"
							>
							</TextField>
						</FormControl>
					</Grid>
					<Grid item xs={4}>
						<FormControl
							margin="normal"
							fullWidth
							required
						>
							<TextField
								disabled
								id="outlined-select-currency"
								select
								label="To"
								className={classes.textField}
								value={currencyTo}
								onChange={(a) => null}
								SelectProps={{
									MenuProps: {
										className: classes.menu,
									},
								}}
								margin="normal"
								variant="outlined"
							>
								{exchangeCurrencies.map(option => (
									<MenuItem key={option.id} value={option.id}>
										{option.label}
									</MenuItem>
								))}
							</TextField>
						</FormControl>
					</Grid>
					{isExchangeSubmitting && <Progress1 text="Submitting" />}
					{!isExchangeSubmitting &&
						<Button
							disabled={amountFrom>props.cash_balance}
							onClick={onSubmit}
							variant="contained"
							className={classes.button}
							component="span">
							Exchange
						</Button>
					}
				</React.Fragment>
			}
		</AppCard>
	);
}

const styles = theme => ({
	container: {
		display: 'flex',
		flexWrap: 'wrap',
	},
	textField: {
	},
	menu: {
		width: 200,
	},
	button: {
		...configTheme.lightButton,
	},
	paper: {
		boxShadow: '0px 20px 40px rgba(0,0,0,0.06)',
		padding: theme.spacing(2),
	}
});

export default withStyles(styles, { withTheme: true })(ExchangeUI); 