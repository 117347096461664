import React from "react";
// eslint-disable-next-line
import Trix from "trix"; // required to pull in Web Component
import "trix/dist/trix.css";
import './styles.css';

class Wysiwyg extends React.Component {
    constructor(props) {
        super(props);
        this.trixInput = React.createRef();
    }
    componentDidMount() {
        this.trixInput.current.addEventListener('trix-blur', event => {
            this.props.onBlur({ target: { value: event.target.innerHTML, id: this.props.id, name: this.props.name } });
        });
        this.trixInput.current.addEventListener('trix-change', event => {
            this.props.onChange({ target: { value: event.target.innerHTML, id: this.props.id, name: this.props.name } });
        })
        this.trixInput.current.innerHTML = this.props.value;
    }
    render() {
        return (
            <trix-editor ref={this.trixInput} style={{ color: 'black' }} />
        );
    }
}

export default Wysiwyg;