import { decorate } from 'mobx';
import GenericStore from './GenericStore';

class ProductStore extends GenericStore {
	apiName = 'products';
	singular = 'product';
	plural = 'products';

	// constructor() {
	// 	super();
	// 	this.init();
	// }
}

decorate(ProductStore, {
});

export default new ProductStore();
