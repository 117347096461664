import React from 'react';
import { Grid, Typography } from '@material-ui/core';


export default function Titles(subTitle, subTitleText, _options) {
	let options = Object.assign({
		width: 12,
		subTitleFontWeight: 500, subTitleTextFontWeight: 500,
		subTitleColour: 'grey', subTitleTextColour: 'darkgrey',
	}, _options);

	return (
		<React.Fragment>
			<Grid item xs={options.width}>
				{!subTitle ? '' :
					<Typography style={{ fontSize: 16, fontWeight: options.subTitleFontWeight, color: options.colour }}>
						{subTitle}
					</Typography>
				}
				{!subTitleText ? '' :
					<Typography color="textSecondary" style={{ fontSize: 16, fontWeight: options.subTitleTextFontWeight, color: options.subTitleTextColour }}>
						{subTitleText}
					</Typography>
				}
			</Grid>
		</React.Fragment>);
}
