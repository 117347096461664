import React from 'react';
import { Grid, Button } from '@material-ui/core'
import ChevronRight from '@material-ui/icons/ArrowForwardIos';

export default function LinesIconTextNext(options, navigateTo, classes, hashVariable, iconFn) {
	return (
		<React.Fragment>
			<Grid item xs={12}>
				{options.map(opt =>
					<Button
						className={classes.button1}
						key={`${hashVariable}-${opt.id}`}
						onClick={() => navigateTo(hashVariable, opt.id)}
						disabled={opt.disabled}>
						{!iconFn ? <span>&nbsp;</span> : iconFn(opt.id)}
						{opt.text}
						<ChevronRight className={classes.icon} />
					</Button>
				)}
			</Grid>
		</React.Fragment>);
}
