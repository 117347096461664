import React from 'react';
import { withStyles } from '@material-ui/core/styles';
// import AddFundsForm from './AddFundsForm';
// import ProductEdit from './ProductEdit';
import { inject, observer } from "mobx-react";
import HUE from '@material-ui/core/colors/grey';
import configTheme from '../../config/theme';
import { OptionSelector } from '../OptionSelector';


const accountTileNameText = HUE[400];

const styles = theme => ({
	menuButton: {
		marginLeft: 12,
		marginRight: 12,
	},
	paper: {
		padding: theme.spacing(2),
		textAlign: 'center',
		color: accountTileNameText,
	},
	button1: configTheme.lightTrypticButton,
	button2: configTheme.lightButton,
	icon: {
		color: 'rgb(130,202,64)'
	},
});

class AdminUIContainer extends React.Component {

	constructor(props){
		super(props);
		this.props.products.list();
	}
	
	onBackToDashboard = () => {
		this.props.routing.push('dashboard');
	}

	onSubmitEdit = async (values) => {
		const result = await this.props.products.update(values);
		if (result===true) {
			window.history.back();
		}
	}

	onSubmitAdd = async (values) => {
		const result = await this.props.products.create(values);
		if (result===true) {
			window.history.back();
		}
	}

	getInitialRecord(hash) {
		let initialValues = {
			name: '',
			issuing_fi: '',
			tenor: 0,
			interest_rate: 0,
			product_type_id: '',
			minimum_amount: 0,
			description: '',
			terms_and_conditions: '',
			currency_id: '',
			active: false,
		};
		if (hash){
			const id = parseInt(hash.substr(6));
			const item = this.props.products.data.find(item => item.id === id);
			if (item) {
				initialValues = Object.assign({}, item);
			}	
		}
		return initialValues;
	}
	navigateTo = (key, value) => {
		this.props.routing.push(value);
	}

	render() {
		const { classes } = this.props;

		const options = [
			{ id: 1, name: 'Post Transactions', slug: 'post-transaction', active: true },
			{ id: 2, name: 'Book Investments', slug: 'book-investment', active: false },
			{ id: 3, name: 'Manage Products', slug: 'products', active: true },
			{ id: 4, name: 'Manage FIs', slug: 'financial-institutions', active: true },
			{ id: 5, name: 'Notifications', slug: 'notifications', active: true },
			{ id: 6, name: 'Logout', slug: 'logout', active: true },
		];
		const title = 'Admin UI';
		const subTitle = '';

		return <OptionSelector classes={classes} onBack={this.navigateBack} options={options} title={title} subTitle={subTitle} navigateTo={this.navigateTo} />;

	}
}

export default withStyles(styles, { withTheme: true })(inject('routing', 'products', 'financialInstitutions', 'currencies')(observer(AdminUIContainer)));