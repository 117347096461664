import React from 'react';
import { AppCard } from '../OptionSelector';
import { TextField, Button, Typography, Grid } from '@material-ui/core';
import Friend from '@material-ui/icons/People';


export default function ReferFriendUI(props) {
	const { classes, onSubmit, isValid, onChange, email } = props;
	return <AppCard classes={classes}>
		<Grid
			container
			spacing={0}
			direction="column"
			alignItems="center"
			justify="center">
			<Grid item xs={12}>
				<Friend className={classes.icon}></Friend>
			</Grid>
		</Grid>
		<Typography className={classes.text} variant="body1">Earn £10 when you refer a friend, and they book their first investment.</Typography>
		<TextField
			className={classes.textbox}
			value={email}
			onChange={onChange}
			placeholder="Enter your friend's email"
			fullWidth
			type="email"
			textAlign="center"
		/>
		<Button
			disabled={!isValid}
			onClick={onSubmit}
			fullWidth
			className={classes.button}>
			Send Referral
			</Button>
	</AppCard>
}

