import { decorate, action } from 'mobx';
import GenericStore from './GenericStore';

class MessageStore extends GenericStore {
	apiName = 'messages';
	singular = 'message';
	plural = 'messages';

	unread = () => {
		let count = 0;
		this.data.forEach(a => a.date_read ? null : count++);
		return count;
	}
	markAsRead = async (message) => {
		if (message && !message.date_read) {
			let msg = {
				id: message.id,
				date_read: new Date().toISOString()
			};
			message.date_read = msg.date_read;
			await this.update(msg, { method: 'patch', noNotifications: true });
			return true;
		}
		return false;
	}
	sendFundsAdded = async (currency) => {
		const message = {
			message: currency
		};
		const sent = await this.create(message, { dontAdd: true });
		return sent;
	}
}

decorate(MessageStore, {
	markAsRead: action
});

export default new MessageStore();
