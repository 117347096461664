import React from 'react';
import { Grid, Paper, Divider } from '@material-ui/core'
import ChevronRight from '@material-ui/icons/ArrowForwardIos';
import IconButton from '@material-ui/core/IconButton';
import { Formik } from 'formik';
import TextField from '@material-ui/core/TextField';
import logo from '../../images/logo.png';

let id = 0;
function nextId() {
	return ++id;
}


export default function (props) {
	const { classes, list } = props;
	const filterList = (list, name) => {
		if (!name) return list;
		return list.filter(item => item.name.toLowerCase().indexOf(name.toLowerCase()) >= 0);
	}
	return (
		<Grid container key={nextId()}
			spacing={2}
			direction="row"
			justify="space-evenly"
			alignItems="center"
		>
			<Grid key={nextId()} item xs={12}>
				<Paper className={classes.paper}
					style={{ boxShadow: '0px 20px 40px rgba(0,0,0,0.06)' }}>
					<Grid container spacing={0}>
						<Grid item xs={12}>
							<Formik
								enableReinitialize
								initialValues={{
									id: '',
									financialInstitutionName: '',
								}}
								onSubmit={(values, actions) => {
									this.handleSubmit(values);
								}}
								render={props => {
									return (
										<React.Fragment>
											<TextField
												className={classes.textFieldContainer}
												InputProps={{ classes: { input: classes.textField } }}
												onChange={props.handleChange}
												onBlur={props.handleBlur}
												value={props.values.financialInstitutionName}
												name="financialInstitutionName"
												label="Search"
												margin="normal"
												type="text"
												fullWidth
											/>

											<table width="100%" style={{ color: 'black' }}>
												{list && (!props.values.financialInstitutionName ? list : filterList(list, props.values.financialInstitutionName)).map((item, idx) => {
													return (
														<tbody key={`financialInstitutionName-${idx}`}>
															<tr>
																<td><img style={{ height: 32, width: 32 }} alt="" src={item.name === 'Nobound' ? logo : item.url_logo} /></td>
																<td style={{ textAlign: 'left' }}>{item.name}</td>
																<td style={{ textAlign: 'left' }}>{item.country?item.country.name:''}</td>
																<td style={{ textAlign: 'right' }}>
																	<IconButton href={`#edit/${item.id}`}>
																		<ChevronRight className={classes.icon} />
																	</IconButton>
																</td>
															</tr>
															<tr>
																<td colSpan={4}>
																	<Divider />
																</td>
															</tr>
														</tbody>
													);
												})}
											</table>

										</React.Fragment>
									);
								}}
							/>
						</Grid>
					</Grid>
				</Paper>
			</Grid>
		</Grid>);
}