import React from 'react';
import { inject } from "mobx-react";
import { Formik } from 'formik';
import { AppCard, Header } from '../OptionSelector';
import { FormControl, TextField, MenuItem, Button, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import configTheme from '../../config/theme';

function SettlementAccountEdit(props) {

	const { classes, currencies, countries, title='Add Account', initialValues, onBack, onSubmit } = props;

	return (
		<AppCard classes={classes}>
			{Header(onBack, null, title)}
			<Formik
				initialValues={initialValues}
				onSubmit={onSubmit}
				render={(props) => {

					return (
						<React.Fragment>
							<Typography>
								The Account MUST be in your name
								{`person's name`}
							</Typography>

							<TextField
								autoFocus
								className={classes.textField}
								InputProps={{ classes: { input: classes.textField } }}
								onChange={props.handleChange}
								onBlur={props.handleBlur}
								value={props.values.name}
								name="name"
								label="Account Nickname"
								margin="dense"
								required
								type="text"
								fullWidth />

							<FormControl
								margin="dense"
								fullWidth
								required>
								<TextField
									select
									label="Country *"
									className={classes.textField}
									value={props.values.country_id}
									onChange={props.handleChange}
									SelectProps={{
										MenuProps: {
											className: classes.menu,
										},
										name: 'country_id'
									}}
									margin="normal"
									name="country_id">
									{countries.data.map(a => (
										<MenuItem key={`countries-${a.id}`} value={a.id}>
											{a.name}
										</MenuItem>
									))}
								</TextField>
							</FormControl>

							<FormControl
								margin="dense"
								fullWidth
								required>
								<TextField
									select
									label="Currency *"
									className={classes.textField}
									value={props.values.currency_id}
									onChange={props.handleChange}
									SelectProps={{
										MenuProps: {
											className: classes.menu,

										},
										name: 'currency_id'
									}}
									margin="normal"
									name="currency_id">
									{currencies.data.map(a => (
										<MenuItem key={`currencies-${a.id}`} value={a.id}>
											{a.name}
										</MenuItem>
									))}
								</TextField>
							</FormControl>

							<TextField
								autoFocus
								className={classes.textField}
								InputProps={{ classes: { input: classes.textField } }}
								onChange={props.handleChange}
								onBlur={props.handleBlur}
								value={props.values.account_number}
								name="account_number"
								label="Account Number"
								margin="dense"
								type="text"
								fullWidth />

							<TextField
								autoFocus
								className={classes.textField}
								InputProps={{ classes: { input: classes.textField } }}
								onChange={props.handleChange}
								onBlur={props.handleBlur}
								value={props.values.sort_code}
								name="sort_code"
								label="Sort code"
								margin="dense"
								type="text"
								fullWidth />

							<TextField
								autoFocus
								className={classes.textField}
								InputProps={{ classes: { input: classes.textField } }}
								onChange={props.handleChange}
								onBlur={props.handleBlur}
								value={props.values.iban}
								name="iban"
								label="IBAN"
								margin="dense"
								type="text"
								fullWidth />

							<TextField
								autoFocus
								className={classes.textField}
								InputProps={{ classes: { input: classes.textField } }}
								onChange={props.handleChange}
								onBlur={props.handleBlur}
								value={props.values.swift_bic}
								name="swift_bic"
								label="SWIFT/BIC"
								margin="dense"
								type="text"
								fullWidth />

							<TextField
								autoFocus
								className={classes.textField}
								InputProps={{ classes: { input: classes.textField } }}
								onChange={props.handleChange}
								onBlur={props.handleBlur}
								value={props.values.aba_routing}
								name="aba_routing"
								label="ABA Routing"
								margin="dense"
								type="text"
								fullWidth />

							&nbsp;
							<Button className={classes.button2} onClick={props.handleSubmit}>Submit</Button>

						</React.Fragment>
					);
				}} />

		</AppCard>
	);
}

const styles = theme => ({
	container: {
		display: 'flex',
		flexWrap: 'wrap',
	},
	textField: {
	},
	menu: {
		width: 200,
	},
	button: {
		...configTheme.lightButton,
	},
	paper: {
		boxShadow: '0px 20px 40px rgba(0,0,0,0.06)',
		padding: theme.spacing(2),
	},
	button2: configTheme.lightButton,
});

export default withStyles(styles, { withTheme: true })(inject('currencies', 'countries', 'settlementAccounts')(SettlementAccountEdit));
