import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { inject, observer } from "mobx-react";
import HUE from '@material-ui/core/colors/grey';
import configTheme from '../../config/theme';
import { OptionSelector, OptionSelector2 } from '../OptionSelector';
import { numberWithCommas, getBaseLocation, formatValue } from '../../libs/common';
import { toCamelCase, urlToHashParams, hashParamsToURL } from '../../libs/common';
import WithdrawUI from '../WithdrawUI/WithdrawUI';
import { ExchangeContainer } from '../Exchange';
import { AccountTransactionsUIContainer } from '../AccountTransactions';
import { DirectPaymentDetails } from '../DirectPayment';
import Flag from 'react-world-flags';

const accountTileNameText = HUE[400];

class AccountActionsUIContainer extends React.Component {
	state = {
		localHistory: [getBaseLocation()],
		baseURL: getBaseLocation(),
		basePath: 'account-actions',
		selectedAccount: 0,
	}

	navigateTo = (key, value) => {
		const hashKey = toCamelCase(key);
		const hashParams = urlToHashParams(window.location.hash);

		hashParams[hashKey] = value;

		const currentState = hashParamsToURL(this.state.baseURL, hashParams);

		window.history.replaceState(null, null, currentState);
		this.setState({ localHistory: [...this.state.localHistory, currentState] },
		);
	}

	navigateBack = () => {
		if (this.state.localHistory.length) {
			let value = this.state.localHistory.pop();

			if (value === window.location.href) {
				return this.navigateBack();
			}

			window.history.replaceState(null, null, value);
			this.setState({ localHistory: [...this.state.localHistory, value] },
			);
		}
	}

	resetState = () => {
		this.setState({ localHistory: [getBaseLocation()] });
		return null;
	}

	onNextAccount = () => {
		this.setState({ selectedAccount: this.state.selectedAccount === 0 ? 1 : 0 });
	}

	openSettlementAccounts = () => {
		this.props.routing.push('/settlement-accounts');
	}

	openKYC = () => {
		this.props.routing.push('/personal-details');
	}

	doWithdraw = async ({ account, amount, settlementAccount }) => {
		amount = amount * 100;
		await this.props.accounts.withdraw({ account, amount, settlementAccount });
	}

	async componentDidMount() {
		await Promise.all(['settlementAccounts','accounts','persons'].map(a => this.props[a].check()));
	}
	async sendMessage(msg){
		const sent = await this.props.messages.sendFundsAdded(msg);
		if (sent) this.props.routing.push('/account-actions');
	}

	render() {
		const { classes, accounts, currencies, persons } = this.props;

		const hashParams = urlToHashParams(window.location.hash);
		const account = accounts.data[this.state.selectedAccount];
		const currencySymbol = currencies.idxById[account.currency_id].symbol;
		const title = `${currencySymbol} ${numberWithCommas(formatValue(account.cash_balance))}`;

		// test for the special case where the user uses some other navigation path to skip right
		// back to the baseUrl, leaving localHistory with ancient history
		if (Object.keys(hashParams).length === 0 && this.state.localHistory.length > 1) {
			return <this.resetState />;
		}

		if (!hashParams.operation) {

			const options = [
				{ id: 1, name: 'Exchange', slug: 'exchange', active: true },
				{ id: 2, name: 'Withdraw', slug: 'withdraw', active: true },
				{ id: 3, name: 'View Transactions', slug: 'account-transactions', active: true },
				{ id: 4, name: 'Add Funds', slug: 'add-funds', active: true },
			];

			const subTitle = `Acc ID: ${account.id}`;

			return (
				<OptionSelector
					classes={classes}
					onBack={this.onNextAccount}
					onPlus={this.onNextAccount}
					options={options}
					title={title}
					subTitle={subTitle}
					navigateTo={this.navigateTo}
					buttonSize={'small'}
					hashVariable='operation'
				/>);

		}

		if (hashParams.operation === 'withdraw') {
			const settlementAccounts = this.props.settlementAccounts.data.filter(a => a.currency_id === account.currency_id);
			return <WithdrawUI onBack={this.navigateBack} account={account} person={persons.person} balance={title} amount={formatValue(account.cash_balance) || 0} settlementAccounts={settlementAccounts} onClick={this.doWithdraw} openKYC={this.openKYC} openSettlementAccounts={this.openSettlementAccounts} />;
		}

		if (hashParams.operation === 'exchange') {
			const otherAccount = accounts.data.filter(a=>a.id!==account.id)[0];
			return <ExchangeContainer onBack={this.navigateBack} account={account} otherAccount={otherAccount} balance={title} cash_balance={formatValue(account.cash_balance)} amount={account.cash_balance || 0} onClick={console.log} />;
		}

		if (hashParams.operation === 'account-transactions') {
			let options = [];
			if (!this.props.transactions.isBusy) {
				options = this.props.transactions.data.map(a => ({
					amount: a.credit_account_id === account.id ? formatValue(a.amount) : -formatValue(a.amount),
					description: a.description,
					date_created: a.date_created,
					pos: new Date(a.date_created).valueOf()
				})).sort((a, b) => b.pos - a.pos);
			}
			return <AccountTransactionsUIContainer onBack={this.navigateBack} account={account} balance={title} onClick={console.log} isBusy={this.props.transactions.isBusy} options={options} />;
		}

		if (hashParams.operation === 'add-funds') {


			if (!hashParams.loadOption) {
				const options = [
					{ id: 1, name: 'Direct Transfer', slug: 'direct-transfer', active: true },
					{ id: 2, name: 'Debit / Credit Card', slug: 'debit-credit-card', active: false },
					{ id: 3, name: 'Apple Pay / Google Pay', slug: 'apple-google-pay', active: false },
					{ id: 4, name: 'PayPal', slug: 'paypal', active: false },
				];
				const title = 'Add Funds';
				const subTitle = 'Choose how to add funds';

				return <OptionSelector onBack={this.navigateBack} classes={classes} options={options} title={title} subTitle={subTitle} hashVariable="operation=add-funds&load-option" navigateTo={this.navigateTo} />;
			}

			const currencies = this.props.currencies.data;

			if (!hashParams.currency) {
				const options = currencies.map(a => ({ id: a.iso3, text: a.name, country_iso3: a.country_iso3, disabled: !a.account_details }));
				const currenciesMap = options.reduce((a, c) => Object.assign(a, { [c.id]: c.country_iso3 }), {});
				const iconFn = (iso3) => {
					return <Flag code={(currenciesMap[iso3]).trim()} width="32" />;
				}
				let hashVariable = `operation=add-funds&load-option=${hashParams.loadOption}&currency`;
				return <OptionSelector2 classes={classes} onBack={this.navigateBack} options={options} title={'Direct Transfer'} subTitle={'Transfer funds directly from your bank'} subTitleText={'Select Transfer Currency'} hashVariable={hashVariable} iconFn={iconFn} navigateTo={this.navigateTo} />;
			}

			let selectedCurrency = false, accountDetails;
			const currencyCode = hashParams.currency;
			accountDetails = currencies.find(v => v.iso3 === currencyCode);
			if (accountDetails) {
				selectedCurrency = currencyCode;
			} else {
				accountDetails = {
					iso3: currencyCode,
					account_details: 'Sorry, we could not find that currency in our system.'
				};
			}

			let accountNumber = 'account not setup';
			if (this.props.session.user && this.props.session.user.attributes) {
				if (this.props.session && this.props.session.user && this.props.session.user.attributes && this.props.session.user.attributes['custom:noboundAccountId']) {
					accountNumber = this.props.session.user.attributes['custom:noboundAccountId'];
				}
				if (this.props.session && this.props.session.person && this.props.session.person.unique_account_id) {
					accountNumber = this.props.session.person.unique_account_id;
				}
			}

			return <DirectPaymentDetails details={accountDetails.account_details} title={hashParams.currency} accountNumber={accountNumber} onBack={this.navigateBack} classes={classes} onSendFundsClick={() => this.sendMessage(selectedCurrency)} />;
		}
	}
}

const styles = theme => ({
	menuButton: {
		marginLeft: 12,
		marginRight: 12,
	},
	paper: {
		padding: theme.spacing(2),
		textAlign: 'center',
		color: accountTileNameText,
	},
	button1: configTheme.lightTrypticButton,
	button2: configTheme.lightButton,
	icon: {
		color: 'rgb(130,202,64)'
	},
});

export default withStyles(styles, { withTheme: true })(inject('routing', 'accounts', 'currencies', 'settlementAccounts', 'transactions', 'session', 'persons', 'messages')(observer(AccountActionsUIContainer)));
