import React from 'react';
import { Grid, Paper, FormControl, FormControlLabel, Button, FormHelperText } from '@material-ui/core';
import { Typography, Table, TableBody, TableCell, TableRow } from '@material-ui/core';
import { Checkbox, IconButton } from '@material-ui/core';
import ChevronLeft from '@material-ui/icons/ArrowBackIos';
import { green } from '@material-ui/core/colors';
import { withStyles } from '@material-ui/core/styles';
import { Formik, Field } from 'formik';
import TextField from '@material-ui/core/TextField';
import { inject, observer } from "mobx-react";
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { numberWithCommas } from '../../libs/common';

let id = 0;
function nextId() {
	return ++id;
}

const GreenCheckbox = withStyles({
	root: {
		color: green[400],
		'&$checked': {
			color: green[600],
		},
	},
	checked: {},
})(props => <Checkbox color="default" {...props} />);

class ProductInvestorNew extends React.Component {
	state = {
		acceptedTCs: false
	}
	render() {
		const { classes, fis, product, currency, initialValues } = this.props;
		const { subTitle, subTitleText, title, onBack, navigateTo, hashVariableList } = this.props;
		const noDescriptionText = `No description here`;
		const noTermsText = 'No terms here!';
		const symbol = currency.symbol;

		const calculateTax = (amount, percentage) => (amount / 100) * percentage;
		const calculateEarnings = (amount, product) => {
			const yearlyEarnings = (amount / 100) * product.interest_rate;
			const yourEarnings = (yearlyEarnings / 365) * product.tenor;
			return parseInt(yourEarnings);
		};

		return (
			<Grid
				container key={nextId()}
				direction="row"
				justify="space-evenly"
				alignItems="center"
				style={{ borderTop: '5px solid rgba(100, 205, 100,0.5)' }}
			>
				<Grid key={nextId()} item xs={12}>
					<Paper className={classes.paper}
						style={{ boxShadow: '0px 20px 40px rgba(0,0,0,0.06)' }}>
						<Grid container spacing={0}>

							<Grid item xs={2} align="center">
								{!onBack ? '&nbsp;' :
									<IconButton onClick={() => onBack()}>
										<ChevronLeft />
									</IconButton>
								}
							</Grid>

							<Grid item xs={8}>
								<Typography variant="h5" color="textPrimary" style={{ fontFamily: 'Nunito', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
									{title}
								</Typography>
							</Grid>

							<Grid item xs={2} align="center">
								&nbsp;
							</Grid>

							<Grid item xs={12}>
								{!subTitle ? '' :
									<Typography style={{ fontSize: 16, fontWeight: 500 }}>
										{subTitle}
									</Typography>
								}
								{!subTitleText ? '' :
									<Typography color="textSecondary" style={{ fontSize: 16, fontWeight: 500, color: 'darkgrey' }}>
										{subTitleText}
									</Typography>
								}
							</Grid>

							<Grid item xs={12}>

								<Table padding="none">
									<TableBody>
										<TableRow style={{ height: 48 }}>
											<TableCell><img style={{ height: 32, width: 32 }} alt="" src={fis.issuing_fi_url_logo} /></TableCell>
											<TableCell style={{ textAlign: 'left', fontWeight: '600', fontSize: '16px', border: 'solid lightgrey', borderWidth: '0px 1px 1px 0px' }}>{product.name}</TableCell>
											<TableCell style={{ textAlign: 'center', fontWeight: '600', fontSize: '16px', border: 'solid lightgrey', borderWidth: '0px 1px 1px 0px' }}>{product.interest_rate}%</TableCell>
											<TableCell style={{ textAlign: 'right', fontWeight: '600', fontSize: '16px', border: 'solid lightgrey', borderWidth: '0px 0px 1px 1px' }}>{product.tenor} days</TableCell>
										</TableRow>
									</TableBody>
								</Table>
								<Table padding="none">
									<TableBody>
										<TableRow style={{ height: 48 }}>
											<TableCell colSpan="2" style={{ textAlign: 'left', fontWeight: '600', fontSize: '16px', color: 'darkgrey', border: 'solid lightgrey', borderWidth: '0px 1px 1px 0px' }}>{fis.name}</TableCell>
											<TableCell style={{ textAlign: 'center', fontWeight: '600', fontSize: '16px', color: 'darkgrey', border: 'solid lightgrey', borderWidth: '0px 1px 1px 0px' }}>{currency.iso3}</TableCell>
											<TableCell style={{ textAlign: 'right', fontWeight: '600', fontSize: '16px', color: 'darkgrey', border: 'solid lightgrey', borderWidth: '0px 0px 1px 1px' }}>{numberWithCommas(product.minimum_amount)} Min</TableCell>
										</TableRow>
										<TableRow style={{ height: 48 }}>
											<TableCell colSpan="4" style={{ padding: 8, border: '0px' }} >
												{/* <strong>Product description:</strong> */}
												<Typography style={{ fontSize: 16 }} dangerouslySetInnerHTML={{ __html: product.description || noDescriptionText }} />
											</TableCell>
										</TableRow>
									</TableBody>
								</Table>

								<Formik
									initialValues={initialValues}
									onSubmit={(values, actions) => {
										navigateTo(hashVariableList, values.amount)
									}}
									render={(props) => {
										return (
											<form onSubmit={props.handleSubmit}>
												<Field
													name="amount"
													render={() =>
														<TextField
															className={classes.textFieldContainer}
															InputProps={{ classes: { input: classes.textField }, style: {color:'green', fontWeight: 600 } }}
															onChange={props.handleChange}
															onBlur={props.handleBlur}
															value={props.values.amount}
															min={initialValues.minimum_amount}
															name="amount"
															label="Enter the amount to invest"
															margin="normal"
															required
															type="number"
															fullWidth
															variant="filled"
															helperText={props.errors['amount'] || ' '}
															error={!!props.errors['amount']}
														/>
													}
													validate={(value) => {
														return value >= initialValues.minimum_amount ? false : `Amount must be > ${initialValues.minimum_amount}`;
													}}
												/>

												<Grid item xs={12}>
													<Typography variant="h6" color="textPrimary">Indicative Earnings</Typography>
													<Table size="small">
														<TableBody>
															<TableRow>
																<TableCell style={{ paddingLeft: '0px', paddingRight: '0px' }}><Typography variant="body1">If you invest:</Typography></TableCell>
																<TableCell align="right" style={{ fontWeight: 'bold', fontSize: 'initial' }}>{symbol} {numberWithCommas(props.values.amount || 0)}</TableCell>
															</TableRow>
															<TableRow>
																<TableCell style={{ paddingLeft: '0px', paddingRight: '0px' }}><Typography variant="body1">For a period of:</Typography></TableCell>
																<TableCell align="right" style={{ fontWeight: 'bold', fontSize: 'initial' }}>{product.tenor} days</TableCell>
															</TableRow>
															<TableRow>
																<TableCell style={{ paddingLeft: '0px', paddingRight: '0px' }}><Typography variant="body1">At a rate of:</Typography></TableCell>
																<TableCell align="right" style={{ fontWeight: 'bold', fontSize: 'initial' }}>{product.interest_rate}%</TableCell>
															</TableRow>
															<TableRow>
																<TableCell style={{ paddingLeft: '0px', paddingRight: '0px' }}><Typography variant="body1">You will earn:</Typography></TableCell>
																<TableCell align="right" style={{ fontWeight: 'bold', fontSize: 'initial' }}>{symbol} {numberWithCommas(calculateEarnings(props.values.amount, product))}</TableCell>
															</TableRow>

															{product.taxOnInterest &&
																<React.Fragment>
																	<TableRow>
																		<TableCell style={{ paddingLeft: '0px', paddingRight: '0px' }}><Typography variant="body1">Tax on interest({calculateTax(calculateEarnings(props.values.amount, product))}%):</Typography></TableCell>
																		<TableCell align="right" style={{ fontWeight: 'bold', fontSize: 'initial' }}>{symbol} {numberWithCommas(calculateEarnings(props.values.amount, product))}</TableCell>
																	</TableRow>
																	<TableRow>
																		<TableCell style={{ paddingLeft: '0px', paddingRight: '0px' }}><Typography variant="body1">You will receive:</Typography></TableCell>
																		<TableCell align="right" style={{ fontWeight: 'bold', fontSize: 'initial' }}>{symbol} {numberWithCommas(calculateEarnings(props.values.amount, product))}</TableCell>
																	</TableRow>
																</React.Fragment>
															}

														</TableBody>
													</Table>
												</Grid>

												<Typography variant="body1" color="textPrimary" style={{ fontStyle: 'italic' }}>These figures are only indicative investment yields.  Exact figures will be available on the summary page.</Typography>

												<ExpansionPanel style={{}}>
													<ExpansionPanelSummary
														expandIcon={<ExpandMoreIcon />}
														aria-controls="panel1a-content"
														id="panel1a-header">
														<Typography className={classes.heading}>Terms and Conditions</Typography>
													</ExpansionPanelSummary>
													<ExpansionPanelDetails>
														<Typography style={{ textAlign: 'left' }} dangerouslySetInnerHTML={{ __html: product.terms_and_conditions || noTermsText }} />
													</ExpansionPanelDetails>
												</ExpansionPanel>

												<Field
													name="terms_and_conditions"
													render={() =>
														<FormControl error={!!props.errors['terms_and_conditions']}>
															<FormControlLabel
																style={{ paddingBottom: 0 }}
																control={
																	<GreenCheckbox
																		checked={props.values.terms_and_conditions}
																		onChange={props.handleChange}
																		onBlur={props.handleBlur}
																		name="terms_and_conditions"
																		id="terms_and_conditions"
																	/>}
																label="I accept the terms and conditions"
															/>
															<FormHelperText>{props.errors['terms_and_conditions']}</FormHelperText>
														</FormControl>}
													validate={(value) => value === true ? false : 'T&Cs must be accepted'}
												/>
												<br />
												<Button
													className={classes.button2}
													type="submit"
													disabled={!props.isValid}
												>Next: Summary</Button>

											</form>
										);
									}}
								/>
							</Grid>
						</Grid>
					</Paper>
				</Grid>
			</Grid>);
	}
}

export default (inject('routing')(observer(ProductInvestorNew)));