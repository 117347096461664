import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import FinancialInstitutionList from './FinancialInstitutionList';
import FinancialInstitutionEdit from './FinancialInstitutionEdit';
import { inject, observer } from "mobx-react";
import { sendNotification } from '../../libs/common';

import HUE from '@material-ui/core/colors/grey';
import configTheme from '../../config/theme';

const accountTileNameText = HUE[400];

const styles = theme => ({
	menuButton: {
		marginLeft: 12,
		marginRight: 12,
	},
	paper: {
		padding: theme.spacing(2),
		textAlign: 'left',
		color: accountTileNameText,
	},
	button1: {
		backgroundColor: 'black',
		color: 'white',
		width: '100%',
		margin: '4px 0px',
		boxShadow: `0px 3px 10px 0px grey`,
		fontFamily: 'Nunito',
		fontSize: 16,
		textTransform: 'initial',
	},
	button2: configTheme.lightButton,
	icon: {
		color: 'rgb(130,202,64)'
	}

});

class FinancialInstitutionContainer extends React.Component {

	constructor(props){
		super(props);
		this.props.financialInstitutions.check();
	}

	onBackToDashboard = () => {
		this.props.routing.push('dashboard');
	}

	onSubmitEdit = async (values) => {
		const result = await this.props.financialInstitutions.update(values);
		if (result === true) {
			sendNotification('Saved');
			window.history.back();
		}
	}

	onSubmitAdd = async (values) => {
		const result = await this.props.financialInstitutions.create(values);
		if (result !== true) {
			sendNotification(result);
			window.history.back();
		}
	}

	getFIDetails(hash) {
		const id = parseInt(hash.substr(6));

		let initialValues = {
			id: '',
			name: '',
			url_logo: '',
			url_website: '',
			profile: '',
			active: false,
			country_id: 0,
			logo_url_new: '',
			fileToUpload: null,
			file: null,
		};
		const item = this.props.financialInstitutions.data.find(item => item.id === id);
		if (item) {
			initialValues = Object.assign({}, item);
		}
		return initialValues;
	}

	async componentDidMount() {
		return Promise.all(['countries', 'financialInstitutions'].map(a => this.props[a].check()));
	}

	render() {
		const { classes } = this.props;
		const financialInstitutions = this.props.financialInstitutions.data;
		const countries = this.props.countries.data;

		const hash = window.location.hash;
		if (hash.length) {
			if (hash === '#add') {
				let initialValues = {
					id: '',
					name: '',
					url_logo: '',
					url_website: '',
					profile: '',
					active: false,
					country_id: 0,
					logo_url_new: '',
					fileToUpload: null,
					file: null,
				};
				return <FinancialInstitutionEdit classes={classes} countries={countries} initialValues={initialValues} onSubmit={this.onSubmitAdd} />;
			}
			if (hash.indexOf('#edit') === 0) {
				let initialValues = this.getFIDetails(hash);
				return <FinancialInstitutionEdit classes={classes} countries={countries} initialValues={initialValues} onSubmit={this.onSubmitEdit} />;
			}
		};

		return <FinancialInstitutionList list={financialInstitutions} classes={classes} onBack={this.onBackToDashboard} />;
	}
}

export default withStyles(styles, { withTheme: true })(inject('routing', 'financialInstitutions', 'countries')(observer(FinancialInstitutionContainer)));