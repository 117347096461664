import * as React from "react";
import { Paper, Typography } from '@material-ui/core';
import { inject, observer } from 'mobx-react';
import { withRouter } from "react-router";

class DashboardHeaderPartnerSummary extends React.Component {
	render() {
		let page = {
			text: `loading user details...`,
			subtext: ``,
		};
		if (this.props.session.user && this.props.session.user.attributes) {
			let accountNo = 'account not setup';
			if (this.props.session && this.props.session.user && this.props.session.user.attributes && this.props.session.user.attributes['custom:noboundAccountId']) {
				accountNo = this.props.session.user.attributes['custom:noboundAccountId'];
			}
			
			if (this.props.session && this.props.session.person && this.props.session.person.unique_account_id) {
				accountNo = this.props.session.person.unique_account_id;
			}
			page = {
				text: `Welcome, ${this.props.session.user.attributes.given_name}`,
				subtext: `${accountNo}`,
			};
		}
		return (
			<Paper style={{ fontFamily: 'Nunito', /* height: 90, */ padding: 16, boxShadow: '0px 2px 4px rgba(0,0,0,0.04)' }}>
				<Typography style={{ fontFamily: 'Nunito' }} variant="h5">{page.text}</Typography>
				{page.subtext ? <Typography style={{ fontFamily: 'Nunito', fontSize: 16, fontWeight: 500, color: 'darkgrey' }}>{page.subtext}</Typography> : ''}
			</Paper>);
	}
}
export default withRouter(inject('session')(observer(DashboardHeaderPartnerSummary)));