import React from 'react';
// import { useState } from 'react';
import { Grid, Paper, FormControl, InputLabel, Select, MenuItem, Button } from '@material-ui/core'
import { Formik } from 'formik';
import TextField from '@material-ui/core/TextField';
import { inject, observer } from "mobx-react";
import Wysiwyg from '../Wysiwyg/Wysiwyg';
// import InputAdornment from '@material-ui/core/InputAdornment';
// import Checkbox from '@material-ui/core/Checkbox';

let id = 0;
function nextId() {
	return ++id;
}

function ProductEdit(props) {
	// let [withTax, withTaxSet] = useState(!!props.initialValues.tax_on_interest);

	const { classes, fis = [], productTypes, initialValues, currencies, accounts } = props;

	const getCurrencySymbol = (c_id) => {
		let currency = null;
		currencies.some(c => currency = (c.id === c_id ? c : null));
		return currency ? currency.symbol : '';
	};
	
	return (
		<Grid container key={nextId()}
			direction="row"
			justify="space-evenly"
			alignItems="center"
		>
			<Grid key={nextId()} item xs={12}>
				<Paper className={classes.paper}
					style={{ boxShadow: '0px 20px 40px rgba(0,0,0,0.06)' }}>
					<Grid container spacing={0}>
						<Grid item xs={12}>
							<Formik
								initialValues={initialValues}
								onSubmit={(values, actions) => {
									props.onSubmit(values);
								}}
								render={(props) => {

									let availableAccounts = [];
									if (props.values){
										availableAccounts = accounts.filter(a=>parseInt(a.fi_id,10)===parseInt(props.values.issuing_fi,10));
									}

									return (
										<form onSubmit={props.handleSubmit}>
											<div style={{ textAlign: 'right' }}>
												<span>{props.values.id ? `id: ${props.values.id}` : 'new product'}</span>
											</div>

											<TextField
												autoFocus
												className={classes.textFieldContainer}
												InputProps={{ classes: { input: classes.textField } }}
												onChange={props.handleChange}
												onBlur={props.handleBlur}
												value={props.values.name}
												name="name"
												label="Product Name"
												margin="normal"
												required
												type="text"
												fullWidth
											/>

											<FormControl
												margin="normal"
												fullWidth
												required>
												<InputLabel htmlFor="active">Issuer</InputLabel>
												<Select
													value={props.values.issuing_fi}
													onChange={props.handleChange}
													onBlur={props.handleBlur}
													name="issuing_fi"
													label="Issuer"
													required
													inputProps={{
														name: 'issuing_fi',
														id: 'issuing_fi',
													}}
												>
													<MenuItem value=""><em>None</em></MenuItem>
													{fis.map(a => <MenuItem value={a.id} key={`fis-${a.id}`}>{a.name}</MenuItem>)}
												</Select>
											</FormControl>

											<FormControl
												margin="normal"
												fullWidth
												required>
												<InputLabel htmlFor="active">Product Type</InputLabel>
												<Select
													value={props.values.product_type_id}
													onChange={props.handleChange}
													onBlur={props.handleBlur}
													name="product_type_id"
													label="Product Type"
													required
													inputProps={{
														name: 'product_type_id',
														id: 'product_type_id',
													}}
												>
													<MenuItem value={null}><em>Select</em></MenuItem>
													{productTypes.map(a => <MenuItem value={a.id} key={`productTypes-${a.id}`}>{a.name}</MenuItem>)}
												</Select>
											</FormControl>

											<FormControl
												margin="normal"
												fullWidth
												required>
												<InputLabel htmlFor="active">Currency</InputLabel>
												<Select
													value={props.values.currency_id}
													onChange={props.handleChange}
													onBlur={props.handleBlur}
													name="currency_id"
													label="Currency"
													required
													inputProps={{
														name: 'currency_id',
														id: 'currency_id',
													}}
												>
													<MenuItem value={null}><em>Select</em></MenuItem>
													{currencies.map(a => <MenuItem value={a.id} key={`currencies-${a.id}`}>{a.name}</MenuItem>)}
												</Select>
											</FormControl>

											<FormControl
												margin="normal"
												fullWidth
												required>
												<InputLabel htmlFor="active">Product Account</InputLabel>
												<Select
													value={props.values.account_id}
													onChange={props.handleChange}
													onBlur={props.handleBlur}
													name="account_id"
													label="Linked Account"
													required
													inputProps={{
														name: 'account_id',
														id: 'account_id',
													}}>
													<MenuItem value={null}><em>Select</em></MenuItem>
													{availableAccounts.map(a => <MenuItem value={a.id} key={`account-${a.id}`} disabled={a.currency_id!==props.values.currency_id}>{a.id}:{a.name}</MenuItem>)}
												</Select>
											</FormControl>


											<TextField
												className={classes.textFieldContainer}
												InputProps={{ classes: { input: classes.textField } }}
												onChange={props.handleChange}
												onBlur={props.handleBlur}
												value={props.values.interest_rate}
												name="interest_rate"
												label="Yield %"
												margin="normal"
												required
												type="number"
												fullWidth
											/>

											<TextField
												className={classes.textFieldContainer}
												InputProps={{ classes: { input: classes.textField } }}
												onChange={props.handleChange}
												onBlur={props.handleBlur}
												value={props.values.tenor}
												name="tenor"
												label="Tenor (days)"
												margin="normal"
												required
												type="number"
												fullWidth
											/>

											<TextField
												className={classes.textFieldContainer}
												InputProps={{ classes: { input: classes.textField } }}
												onChange={props.handleChange}
												onBlur={props.handleBlur}
												value={props.values.minimum_amount}
												name="minimum_amount"
												label={`Minimum Amt (${getCurrencySymbol(props.values.currency_id)})`}
												margin="normal"
												required
												type="number"
												fullWidth
											/>

											{/* <TextField
												className={classes.textFieldContainer}
												onChange={props.handleChange}
												onBlur={props.handleBlur}
												value={props.values.minimum_amount}
												name="minimum_amount"
												label={`Minimum Amt (${getCurrencySymbol(props.values.currency_id)})`}
												margin="normal"
												required
												type="number"
												fullWidth
												InputProps={{
													classes: { input: classes.textField },
													startAdornment: <InputAdornment position="start">
														<Checkbox
															checked={withTax}
															onChange={withTaxSet}
															value={true}
															color="primary"
															inputProps={{
																'aria-label': 'secondary checkbox',
															}}
														/>
													</InputAdornment>,
												}}
											/> */}

											<InputLabel htmlFor="description">Description</InputLabel>
											<Wysiwyg
												id="description"
												name="description"
												value={props.values.description}
												onChange={props.handleChange}
												onBlur={props.handleBlur}
											/>

											<InputLabel htmlFor="terms_and_conditions">Ts & Cs</InputLabel>
											<Wysiwyg
												id="terms_and_conditions"
												name="terms_and_conditions"
												value={props.values.terms_and_conditions}
												onChange={props.handleChange}
												onBlur={props.handleBlur}
											/>

											<br />
											<Button className={classes.button2} type="submit">Submit</Button>

										</form>
									);
								}}
							/>
						</Grid>
					</Grid>
				</Paper>
			</Grid>
		</Grid>
	);
}

export default (inject('routing')(observer(ProductEdit)));